import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./scss/main.scss";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue3-cookies";
import Popper from "vue3-popper";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { VueReCaptcha } from "vue-recaptcha-v3";


// import { VueReCaptcha } from "vue-recaptcha-v3";

let app = createApp(App)
app.config.isCustomElement = tag => tag.startsWith('app-')
app.use(VueCookies)
    .use(VueAxios, axios)
    .use(store)
    .use(router)
    .use(VueReCaptcha, { siteKey: "6Lc8dvEiAAAAAFc1nd77cVOYYGkWfCiLrgAS2_Rs" })
    // .use(VueReCaptcha, { siteKey: "6Ld8NOMiAAAAAKD6tKbzIXiW-8CJPkmRZO7HpDZf" })
app.config.globalProperties.updateFormValue = (path, value) => {
    store.commit('updateStudentForm', { name: path, value: value })
};

app.component("PopperPopper", Popper);
app.component('DatepickerDate', Datepicker);

app.mount("#app");