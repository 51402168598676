<template>
    <main-layout>
        <div class="-pt-nav ">
            <div class="container background__pg-applications -pt-sm-8  -pb-xs1-4 -pb-sm-12">
                <section-layout class="section-news d-flex justify-content-center">
                    <div class="section-news__content -pt-4 -pt-sm-0 col-24 col-sm-16">
                        <p class="heading-40 color-blue text-center -pb-4 -pb-sm-4">Lista zwycięzców</p>
                        <div class="row">
                            <div class="col-md-16 offset-md-4 mb-5">
                                <p class="text-center text-18" style="line-height: 1.6em;">
                                    Poznaj wszystkich laureatów tej edycji Enei Akademii Talentów. Zobacz, kogo wybrali
                                    internauci oraz jury i do kogo powędrowały stypendia i granty. <br>
                                    <span class="heading-18 color-blue">
                                        Gratulujemy wszystkim
                                        zwycięzcom i życzymy dalszych sukcesów!
                                    </span>
                                </p>
                            </div>
                        </div>
                        <p class="heading-24 form__title text-center">
                            Wybierz rodzaj finalisty
                        </p>
                        <div class="form__pt-16 -pb-4 d-flex justify-content-center">
                            <div class="button__radio_2">
                                <label class="d-block d-md-inline-block"
                                    :class="{ 'button__radio-red': filter.type == 'primary_school' }"><input
                                        type="radio" v-model="filter.type" value="primary_school" />Szkoła
                                    podstawowa</label>
                                <label class="d-block d-md-inline-block"
                                    :class="{ 'button__radio-red': filter.type == 'secondary_school' }"><input
                                        type="radio" v-model="filter.type" value="secondary_school" />Szkoła
                                    ponadpodstawowa</label>
                            </div>
                        </div>
                        <div class="applications">
                            <div class="">
                                <div class="mb-5 justify-content-between">
                                    <div class="mb-3">
                                        <div class="heading-24 form__title mb-4 text-center text-md-start">
                                            Wybierz kategorię
                                        </div>

                                        <div class="applications__category-filter">
                                            <button class="heading-18 filter-category py-2 py-md-0 ps-md-0"
                                                :class="{ 'filter-category--active': filter.category == NonNullable }"
                                                @click="() => filter.category = null">Wszystkie</button>
                                            <button class="heading-18 filter-category py-2 py-md-0"
                                                :class="{ 'filter-category--active': filter.category == 'sport' }"
                                                @click="() => filter.category = 'sport'">Sport</button>
                                            <button class="heading-18 filter-category py-2 py-md-0"
                                                :class="{ 'filter-category--active': filter.category == 'nauka' }"
                                                @click="() => filter.category = 'nauka'">Nauka</button>
                                            <button class="heading-18 filter-category py-2 py-md-0"
                                                :class="{ 'filter-category--active': filter.category == 'sztuka' }"
                                                @click="() => filter.category = 'sztuka'">Sztuka</button>
                                            <button class="heading-18 filter-category py-2 py-md-0"
                                                :class="{ 'filter-category--active': filter.category == 'social_commitment' }"
                                                @click="() => filter.category = 'zaangażowanie społeczne'">Zaangażowanie
                                                społeczne i ekologia</button>
                                        </div>
                                    </div>
                                    <div style="max-width: 250px;" class="d-flex flex-column justify-content-between">
                                        <p class="color-blue">Wyszukiwarka</p>
                                        <div class="applications__search">
                                            <input class="applications__search-input" placeholder="Szukaj finalisty"
                                                type="text" v-model="searchValue" id="">
                                            <button class="applications__search-button"
                                                @click="() => filter.search = searchValue">Szukaj</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="applications__items" id="list">
                                <div v-for="(application, index) in displayedApplications" :key="'application' + index"
                                    class="applications__item">
                                    <div class="applications__img-wrapper">
                                        <img class="w-100" :src="application.img" alt="" />
                                        <img v-if="application.winner == 1" src="@/assets/Badge-JURY.svg" alt=""
                                            class="applications__img-budge" />
                                        <img v-if="application.winner == 2" src="@/assets/Badge-INTERNAUCI.svg" alt=""
                                            class="applications__img-budge" />
                                    </div>
                                    <div class="applications__content-wrapper py-3 py-md-1">
                                        <div v-if="application.type != 'organization'" class="text-capitalize mb-1">
                                            Kategoria: {{ application.category }}</div>
                                        <div class="heading-18 color-blue mb-2">
                                            {{ application.application_name }}
                                            <span v-if="application.class">
                                                - {{ application.class }} klasa
                                            </span>
                                        </div>

                                        <div class="max-3">
                                            {{ descSlice(application.application_desc) }}</div>
                                    </div>
                                    <div class="applications__link-wrapper">
                                        <router-link :to="{ name: 'applicationsItem', params: { id: application.id } }"
                                            class="button__red">Przejdź do profilu<img src="@/assets/right-btn.svg"
                                                alt="" class="button__arrow" /></router-link>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center" v-if="paginateLoad">
                                    <!-- Paginacja -->
                                    <div class="container d-flex justify-content-center">
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination d-flex">

                                                <li class="page-item">
                                                    <button type="button" class="page-link" v-if="page != 1"
                                                        @click="() => { page--; scrollToElement() }"> <img
                                                            src="@/assets/arrow-prev.svg" alt="" class="" /> </button>
                                                </li>
                                                <li class="page-item d-flex">
                                                    <button type="button" class="page-link"
                                                        :class="{ 'page-link--active': page == pageNumber }"
                                                        v-for="(pageNumber, index) in pages" :key="'paginate' + index"
                                                        @click="() => { page = pageNumber; scrollToElement() }"> {{
                                                            pageNumber
                                                        }} </button>
                                                </li>
                                                <li class="page-item">
                                                    <button type="button" @click="() => { page++; scrollToElement() }"
                                                        v-if="page < pages.length" class="page-link"> <img
                                                            src="@/assets/arrow-next.svg" alt="" class="" /> </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section-layout>
            </div>
        </div>
        <VoteForm v-if="showModal.show" :id="activeId" @close-modal="() => showModal.show = false" />
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import VoteForm from "@/components/forms/VoteForm.vue";
import axios from "axios";


// Sections

export default {
    components: {
        MainLayout,
        VoteForm
    },
    methods: {
        descSlice(desc) {
            if (desc)
                return desc.slice(0, 205) + '...';
            else
                return '';

        },
        scrollToElement() {

            const el = document.querySelector('#list');

            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({ behavior: 'smooth' });

                const pos = el.style.position;
                const top = el.style.top;
                el.style.position = 'relative';
                el.style.top = '-200px';
                el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                el.style.top = top;
                el.style.position = pos;
            }
        },
        setPages() {
            this.page = 1;
            this.pages = [];
            let numberOfPages = Math.ceil(this.filteredApplicatons.length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        paginate(applications) {
            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return applications.slice(from, to);
        },
        openModal(id) {
            this.activeId = id;
            this.showModal.show = true;
        },
        filterApolications(applications) {
            let filteredApplicatons = applications.filter((item) => {
                return item.type == this.filter.type;
            })

            if (this.filter.category != null) {
                filteredApplicatons = filteredApplicatons.filter((item) => {
                    return item.category == this.filter.category;
                })
            }

            if (this.filter.search != null && this.filter.search != '') {
                const regex = new RegExp(this.filter.search, 'gmi');
                filteredApplicatons = filteredApplicatons.filter((item) => {
                    console.log('name', item.application_name)
                    return regex.test(item.application_name);
                })
            }

            console.log(filteredApplicatons)
            this.filteredApplicatons = filteredApplicatons;
        }
    },
    computed: {
        pagesList() {
            return this.pages.slice(this.page - 1, this.page + 2)
        },
        displayedApplications() {
            return this.paginate(this.filteredApplicatons);
        },
        pagesLength() {
            return this.pages.length;
        }
    },
    watch: {
        filteredApplicatons() {
            this.setPages();
        },
        'filter': {
            handler() {
                this.paginateLoad = false;
                this.filterApolications(this.applications);
                this.setPages();
                setTimeout(() => {
                    this.paginateLoad = true;
                }, 200);
            },
            deep: true
        },
    },
    filters: {
        trimWords(value) {
            return value.split(" ").splice(0, 20).join(" ") + '...';
        }
    },
    data() {
        return {
            paginateLoad: true,
            searchValue: '',
            applications: [],
            filteredApplicatons: [],
            page: 1,
            perPage: 6,
            pages: [],
            activeId: null,
            showModal: {
                show: false
            },
            filter: {
                type: 'primary_school',
                category: null,
                search: null
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        axios
            .all([
                axios({
                    method: "get",
                    url: process.env.VUE_APP_API_BASE_URL + "applications/winners",
                }),
            ])
            .then(
                axios.spread((applicationsResponse) => {
                    next((vm) => {
                        vm.applications = applicationsResponse.data;
                        vm.filterApolications(applicationsResponse.data);
                    });
                })
            )
            .catch((error) => {
                this.errors = error;
            });
    },

};
</script>
