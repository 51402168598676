<template>
    <main-layout>
        <div v-if="type == 'show-form' && $route.params.type == 'student'">
            <div v-if="showFormII === 1">
                <Step2Student />
            </div>
            <div class="container background__icons-sm -pt-4 -pb-2 -pb-sm-12 -pt-sm-8 d-flex justify-content-center" v-else>
                <section-layout class="section-news__items col-sm-18">
                    <div class="section-news__content">
                        <p class="text-16"></p>
                        <p class="heading-40 form__title color-blue -pb-4">Musisz jeszcze poczekać.</p>
                        <div class="text-16 section-news__text">
                            Formularz będzie dostępny od 27 listopada 2023 do 29 grudnia 2023.
                        </div>
                        <div class="-pt-4 -pb-1 text-center">
                            <router-link :to="{ name: 'home' }" class="button__red">Powrót na stronę główną
                            </router-link>
                        </div>
                    </div>
                </section-layout>
            </div>
        </div>
        <div v-else-if="type == 'show-form' && $route.params.type == 'organizacja'">
            <Step2Organization />
        </div>
        <div class="-pt-nav" v-else>
            <div class="container background__icons-sm -pt-4 -pb-2 -pb-sm-12 -pt-sm-8 d-flex justify-content-center">
                <section-layout class="section-news__items col-sm-18">
                    <div class="section-news__content" v-if="!type">
                        <p class="text-16"></p>
                        {{ type }}
                        <p class="heading-40 form__title color-blue -pb-4">Niestety coś poszło nie tak.</p>
                        <div class="text-16 section-news__text">
                            Serwer nie pobrał danych
                        </div>
                        <div class="-pt-4 -pb-1 text-center">
                            <router-link :to="{ name: 'home' }" class="button__red">Powrót na stronę główną
                            </router-link>
                        </div>
                    </div>
                    <div class="section-news__content" v-if="type == 'finalist-accept'">
                        <p class="text-16"></p>
                        <p class="heading-40 form__title color-blue -pb-4">Już wypełniłeś formularz dla finalisty</p>
                        <div class="text-16 section-news__text">
                            Twoje zgłoszenie finalsity zostało już wypełnione. Jeżeli to nie Ty wypełniłeś, zgłoś się
                            do organizatora konkursu.
                        </div>
                        <div class="-pt-4 -pb-1 text-center">
                            <router-link :to="{ name: 'home' }" class="button__red">Powrót na stronę główną
                            </router-link>
                        </div>
                    </div>
                    <div class="section-news__content" v-if="type == 'no-finalist' || type == 'not-found'">
                        <p class="text-16"></p>
                        <p class="heading-40 form__title color-blue -pb-4">Nie zakwalifikowałeś/aś się do finału.</p>
                        <div class="text-16 section-news__text">
                            Niestety nie dostałeś się do finału.
                        </div>
                        <div class="-pt-4 -pb-1 text-center">
                            <router-link :to="{ name: 'home' }" class="button__red">Powrót na stronę główną
                            </router-link>
                        </div>
                    </div>
                </section-layout>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import Step2Organization from "@/components/forms/Step2Organization.vue";
import Step2Student from "@/components/forms/Step2Student.vue";

import axios from "axios";

export default {
    data() {
        return {
            type: null,
            application: null
        }
    },
    beforeRouteEnter(to, from, next) {
        axios
            .all([
                axios({
                    method: "get",
                    url: process.env.VUE_APP_API_BASE_URL + "applications/finalist-data/" + to.params.uuid,
                }),
            ])
            .then(
                axios.spread((response) => {
                    next((vm) => {
                        if (response.data.status == 'finalist-accept') {
                            vm.type = 'finalist-accept'
                        }
                        if (response.data.status == 'no-finalist') {
                            vm.type = 'no-finalist'
                        }
                        if (response.data.status == 'success') {
                            vm.type = 'show-form';
                            vm.application = response.data.application;
                        }
                    });
                })
            )
            .catch((error) => {
                next((vm) => {
                    if (error.response.data.status == 'not-found') {
                        vm.type = 'not-found'
                    }
                });
            });
    },
    mounted() {
        if (!this.application) return;
        this.$store.commit('removeAllErrors');
        this.$store.commit('resetFormStudent');

        if (this.application.type == "organization") {
            if (this.application.application_organizations) {
                this.updateFormValue('formOrganizationStep2.organization.name', this.application.application_organizations.name)
                this.updateFormValue('formOrganizationStep2.organization.address.city', this.application.application_organizations.address.city)
                this.updateFormValue('formOrganizationStep2.organization.address.street', this.application.application_organizations.address.street)
                this.updateFormValue('formOrganizationStep2.organization.address.postal_code', this.application.application_organizations.address.postal_code)
                this.updateFormValue('formOrganizationStep2.organization.address.number', this.application.application_organizations.address.number)
                this.updateFormValue('formOrganizationStep2.organization.address.voivodeship', this.application.application_organizations.address.voivodeship)
                this.updateFormValue('formOrganizationStep2.organization.address.community', this.application.application_organizations.address.community)
                if (this.application.application_organizations.contact_address) {
                    this.updateFormValue('formOrganizationStep2.organization.contact_address.city', this.application.application_organizations.contact_address.city)
                    this.updateFormValue('formOrganizationStep2.organization.contact_address.street', this.application.application_organizations.contact_address.street)
                    this.updateFormValue('formOrganizationStep2.organization.contact_address.postal_code', this.application.application_organizations.contact_address.postal_code)
                    this.updateFormValue('formOrganizationStep2.organization.contact_address.number', this.application.application_organizations.contact_address.number)
                    this.updateFormValue('formOrganizationStep2.organization.contact_address.voivodeship', this.application.application_organizations.contact_address.voivodeship)
                    this.updateFormValue('formOrganizationStep2.organization.contact_address.community', this.application.application_organizations.contact_address.community)
                }
            }
            this.updateFormValue('formOrganizationStep2.person.name', this.application.application_person.name)
            this.updateFormValue('formOrganizationStep2.person.surname', this.application.application_person.surname)
            this.updateFormValue('formOrganizationStep2.person.phone', this.application.application_person.phone)
            this.updateFormValue('formOrganizationStep2.person.email', this.application.application_person.email)
            this.updateFormValue('formOrganizationStep2.person.workplace', this.application.application_person.workplace)
        } else if (this.application.type == 'secondary_school' || this.application.type == 'primary_school') {
            this.updateFormValue('formStudentStep2.student.name', this.application.application_student.name)
            this.updateFormValue('formStudentStep2.student.surname', this.application.application_student.surname)
            this.updateFormValue('formStudentStep2.student.phone', this.application.application_student.phone)
            this.updateFormValue('formStudentStep2.student.email', this.application.application_student.email)
            this.updateFormValue('formStudentStep2.student.class', this.application.application_student.class)
            this.updateFormValue('formStudentStep2.student.birth_date', this.application.application_student.birth_date)
            this.updateFormValue('formStudentStep2.student.category', this.application.application_student.category)
            if (this.application.application_student) {
                this.updateFormValue('formStudentStep2.student.address.city', this.application.application_student.address.city)
                this.updateFormValue('formStudentStep2.student.address.street', this.application.application_student.address.street)
                this.updateFormValue('formStudentStep2.student.address.postal_code', this.application.application_student.address.postal_code)
                this.updateFormValue('formStudentStep2.student.address.number', this.application.application_student.address.number)
                this.updateFormValue('formStudentStep2.student.address.voivodeship', this.application.application_student.address.voivodeship)
                this.updateFormValue('formStudentStep2.student.address.community', this.application.application_student.address.community)
                if (this.application.application_student.contact_address) {
                    this.updateFormValue('formStudentStep2.student.contact_address.city', this.application.application_student.contact_address.city)
                    this.updateFormValue('formStudentStep2.student.contact_address.street', this.application.application_student.contact_address.street)
                    this.updateFormValue('formStudentStep2.student.contact_address.postal_code', this.application.application_student.contact_address.postal_code)
                    this.updateFormValue('formStudentStep2.student.contact_address.number', this.application.application_student.contact_address.number)
                    this.updateFormValue('formStudentStep2.student.contact_address.voivodeship', this.application.application_student.contact_address.voivodeship)
                    this.updateFormValue('formStudentStep2.student.contact_address.community', this.application.application_student.contact_address.community)
                }
            }
            this.updateFormValue('formStudentStep2.person.name', this.application.application_person.name)
            this.updateFormValue('formStudentStep2.person.surname', this.application.application_person.surname)
            this.updateFormValue('formStudentStep2.person.phone', this.application.application_person.phone)
            this.updateFormValue('formStudentStep2.person.email', this.application.application_person.email)
            this.updateFormValue('formStudentStep2.person.address', this.application.application_person.address)
            this.updateFormValue('formStudentStep2.person.contact_address', this.application.application_person.contact_address)
        }
    },
    computed: {
        showFormII() {
            return this.$store.state.showFormII
        }
    },
    watch: {},
    components: {
        MainLayout,
        Step2Organization,
        Step2Student
    },
    methods: {},
};
</script>
