<template>
    <main-layout>
        <div class="-pt-nav  -pb-xs1-4 -pb-sm-12">
            <SectionBanner />
            <div class="container">
                <SectionAbout />
                <SectionHowToWin />
            </div>
            <SectionPrize />
            <div class="container">
                <div class="-pt-sm-12">
                    <SectionNews />
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";

// Sections
import SectionBanner from "@/components/sections/SectionBanner";
import SectionAbout from "@/components/sections/SectionAbout";
import SectionHowToWin from "@/components/sections/SectionHowToWin";
import SectionPrize from "@/components/sections/SectionPrize";
import SectionNews from "@/components/sections/SectionNews";

export default {
    components: {
        MainLayout,
        SectionBanner,
        SectionAbout,
        SectionHowToWin,
        SectionPrize,
        SectionNews,
    },
    computed: {
    }
};
</script>
