<template>
    <div class="form-data">
        <radio-input v-model.number="form.student_board_select" :data="inputs.student_board_select" />
        <textarea-ianput v-show="form.student_board_select == 'yes'" v-model="form.student_board"
            :data="inputs.student_board" />

        <radio-input v-model.number="form.social_activity_select" :data="inputs.social_activity_select" />
        <textarea-ianput v-show="form.social_activity_select == 'yes'" v-model="form.social_activity"
            :data="inputs.social_activity" />

    </div>
</template>

<script>
import RadioInput from "@/components/inputs/RadioInput.vue";
import TextareaIanput from "@/components/inputs/TextareaIanput.vue";


export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],
    data() {
        return {
            inputs: {
                student_board_select: {
                    type: 'radio',
                    title: 'Czy uczestnik działa w samorządzie szkolnym lub klasowym?',
                    subtitle: null,
                    flexRow: true,
                    mandatory: '*',
                    options: [
                        {
                            name: 'tak',
                            value: 'yes'
                        },
                        {
                            name: 'nie',
                            value: 'no'
                        }
                    ],
                    model: 'student_board_select',
                    stage: 'formStudent.student.student_board_select'
                },
                social_activity_select: {
                    type: 'radio',
                    title: 'Czy uczestnik konkursu jest zaangażowany w działalność społeczną? ',
                    subtitle: 'Działalnością społeczną może być np. wolontariat, harcerstwo, działalność w lokalnych organizacjach, w tym w organizacjach ekologicznych, w samorządzie szkolnym, redagowanie gazetki szkolnej, działalność na rzecz kultury itp.',
                    mandatory: '*',
                    flexRow: true,
                    options: [
                        {
                            name: 'tak',
                            value: 'yes'
                        },
                        {
                            name: 'nie',
                            value: 'no'
                        }
                    ],
                    model: 'social_activity_select',
                    stage: 'formStudent.student.social_activity_select'
                },
                student_board: {
                    type: 'textarea',
                    title: 'W jakim samorządzie działa uczestnik konkursu?',
                    placeholder: 'W jakim samorządzie działa uczestnik konkursu?',
                    subtitle: null,
                    mandatory: '*',
                    tooltip: 'Należy opisać, w jakim samorządzie działa i jakie są jego zadania.',
                    model: 'student_board',
                    stage: 'formStudent.student.student_board'
                },
                social_activity: {
                    type: 'textarea',
                    title: 'Charakterystyka zaangażowania uczestnika',
                    placeholder: 'Charakterystyka zaangażowania uczestnika',
                    subtitle: null,
                    mandatory: '*',
                    tooltip: 'Należy podać, w jakiego typu działalność społeczną jest zaangażowany uczestnik konkursu, na czym to zaangażowanie polega oraz jaka jest jego częstotliwość.',
                    model: 'social_activity',
                    stage: 'formStudent.student.social_activity'
                },
            },
            form: {
                student_board_select: null,
                student_board: '',
                social_activity_select: null,
                social_activity: '',
            }
        }
    },
    components: {
        RadioInput,
        TextareaIanput
    },
    computed: {
    },
    watch: {
        form: {
            handler(newValue) {
                this.$emit('update:modelValue', newValue)
            },
            deep: true
        }
    },
    methods: {
    },
};
</script>
