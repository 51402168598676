<template>
    <div class="form-data">
        <div class="frame -pt-4">
            <div class="form-check d-flex align-items-center">
                <input class="form-check-input" v-model="form.school" type="checkbox" value="" id="w_szkole">
                <label class="form-check-label -pl-1 text-20" for="w_szkole">w szkole</label>
            </div>
            <div class="form-check d-flex align-items-center form__pt-24">
                <input class="form-check-input" v-model="form.librus" type="checkbox" value="" id="z_librusa">
                <label class="form-check-label -pl-1 text-20" for="z_librusa">z Librusa</label>
            </div>
            <div class="form-check d-flex align-items-center form__pt-24">
                <input class="form-check-input" v-model="form.social_media" type="checkbox" value="" id="social_media">
                <label class="form-check-label -pl-1 text-20" for="social_media">z reklam w mediach
                    społecznościowych</label>
            </div>
            <div class="form-check d-flex align-items-center form__pt-24">
                <input class="form-check-input" v-model="form.spot" type="checkbox" value="" id="spot">
                <label class="form-check-label -pl-1 text-20" for="spot">spot podczas wydarzenia sportowego</label>
            </div>
            <div class="form-check d-flex align-items-center form__pt-24">
                <input class="form-check-input" v-model="form.poster" type="checkbox" value="" id="poster">
                <label class="form-check-label -pl-1 text-20" for="poster">z materiałów drukowanych (np. ulotka,
                    plakat)</label>
            </div>
            <div class="form-check d-flex align-items-center form__pt-24">
                <input class="form-check-input" v-model="form.instagram" type="checkbox" value="" id="instagram">
                <label class="form-check-label -pl-1 text-20" for="instagram">od influencera na Instagramie</label>
            </div>
            <div class="form-check d-flex align-items-center form__pt-24">
                <input class="form-check-input" v-model="form.friend" type="checkbox" value="" id="frend">
                <label class="form-check-label -pl-1 text-20" for="frend">od nauczyciela, znajomego lub rodziny</label>
            </div>
            <div class="form-check d-flex align-items-center form__pt-24">
                <input class="form-check-input" v-model="form.other" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label -pl-1 text-20" for="flexCheckDefault">inne (należy wskazać jakie)</label>
            </div>
            <text-input class="form__pt-24 col-sm-12" v-if="form.other" v-model="form.other_text"
                :data="inputs.other_text" :errors="errors" />
        </div>
    </div>
</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";


export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],
    data() {
        return {
            inputs: {
                other_text: {
                    type: 'text',
                    title: 'Wskaż miejsce ',
                    placeholder: 'Wskaż miejsce ',
                    subtitle: null,
                    model: 'other_text'
                },

            },
            form: {
                school: null,
                librus: null,
                social_media: null,
                spot: null,
                poster: null,
                instagram: null,
                friend: null,
                other: null,
                other_text: '',
            }
        }
    },
    components: {
        TextInput
    },
    computed: {
    },
    watch: {
        form: {
            handler(newVal) {
                this.$emit('update:modelValue', newVal)
                this.updateFormValue('formStudent.student.how_know', newVal);
            },
            deep: true
        }
    },
    methods: {
    },
};
</script>
