<template>
    <div class="form-data">
        <textarea-ianput :data="inputs.purpose_project" />
        <textarea-ianput :data="inputs.desc" />
    </div>
</template>

<script>
import TextareaIanput from "@/components/inputs/TextareaIanput.vue";

export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],

    components: {
        TextareaIanput,
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    data() {
        return {
            inputs: {
                purpose_project: {
                    type: 'textarea',
                    title: 'Krótka charakterystyka Uczestnika i jego największe osiągnięcia, które zostaną umieszczone na stronie Enei Akademii Talentów w ramach profilu Uczestnika',
                    placeholder: 'Krótka charakterystyka Uczestnika i jego największe osiągnięcia, które zostaną umieszczone na stronie Enei Akademii Talentów w ramach profilu Uczestnika',
                    subtitle: null,
                    mandatory: '*',
                    maxlength: 3000,
                    tooltip: 'Opis powinien zostać przygotowany w 1 osobie i uwzględniać opis Uczestnika i jego pasji oraz osiągnieć, np. Jestem 12 letnim fanem piłki nożnej). Opis ten będzie widoczny na profilu Ucznia dla osób głosujących.',
                    model: 'characterization',
                    stage: 'formStudentStep2.project.characterization'
                },
                desc: {
                    type: 'textarea',
                    title: 'Plan stypendialny',
                    placeholder: 'Plan stypendialny',
                    subtitle: null,
                    mandatory: '*',
                    maxlength: 3000,
                    tooltip: 'Opis planu, wg którego Uczestnik zamierza wykorzystać stypendium, jeśli takie zostanie mu przyznane. Opis powinien uwzględniać konkretne wydatki wraz z informacją o jego planowanym koszcie.',
                    model: 'scholarship_plan',
                    stage: 'formStudentStep2.project.scholarship_plan'
                },
            },
        }
    },
};
</script>
