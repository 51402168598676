<template>
    <div class="form-data">
        <radio-input v-model="form.category" :data="inputs.category" />
        <div v-if="get_type && form.category">
            <div class="heading-24 form__title text-center -pt-4">
                Średnia ocen
            </div>
            <div class="text-center text-20">w roku szkolnym 2023/2024</div>
            <div class="d-flex justify-content-center">
                <div class="col-sm-9">
                    <p class="text-18 form__title form__pb-8 form__pt-24">Wybierz średnią ocen<span
                            class="form__label form__label--red">*</span></p>
                    <vue-select :close-on-select="true" v-model="form.average" placeholder="Wybierz średnią ocen"
                        :options="inputs.average.options[get_type][form.category]" label-by="name" value-by="value">
                        <template #label="{ selected }">
                            <template v-if="selected">
                                <div>{{ selected.name }}</div>
                            </template>
                        </template>
                    </vue-select>
                </div>
            </div>
            <div class="text-danger text-center" v-if="averageError">
                {{ averageError }}
            </div>
        </div>
        <radio-input v-model="form.behavior" :data="inputs.behavior" />
    </div>
</template>

<script>
import RadioInput from "@/components/inputs/RadioInput.vue";
import VueNextSelect from 'vue-next-select';


export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],
    data() {
        return {
            inputs: {
                category: {
                    type: 'radio',
                    title: 'Kategoria talentu',
                    subtitle: null,
                    mandatory: '*',
                    options: [
                        {
                            name: 'sport',
                            value: 'sport'
                        },
                        {
                            name: 'sztuka',
                            value: 'art'
                        },
                        {
                            name: 'nauka',
                            value: 'science'
                        },
                        {
                            name: 'zaangażowanie społeczne i ekologia',
                            value: 'social'
                        }
                    ],
                    model: 'category',
                    stage: 'formStudent.student.category'
                },
                average: {
                    options: {
                        primary_school: {
                            sport: [
                                {
                                    name: '>5,2',
                                    value: 5
                                },
                                {
                                    name: '5,00 - 5,2',
                                    value: 4
                                },
                                {
                                    name: '4,61 - 4,99',
                                    value: 3
                                },
                                {
                                    name: '4,31 - 4,60',
                                    value: 2
                                },
                                {
                                    name: '4,00 - 4,30',
                                    value: 1
                                },
                            ],
                            social: [
                                {
                                    name: '>5,2',
                                    value: 5
                                },
                                {
                                    name: '5,00 - 5,2',
                                    value: 4
                                },
                                {
                                    name: '4,61 - 4,99',
                                    value: 3
                                },
                                {
                                    name: '4,31 - 4,60',
                                    value: 2
                                },
                                {
                                    name: '4,00 - 4,30',
                                    value: 1
                                },
                            ],
                            science: [
                                {
                                    name: '>5,3',
                                    value: 5
                                },
                                {
                                    name: '5,00 - 5,3',
                                    value: 4
                                },
                                {
                                    name: '4,81 - 4,99',
                                    value: 3
                                },
                                {
                                    name: '4,61 - 4,80',
                                    value: 2
                                },
                                {
                                    name: '4,50 - 4,60',
                                    value: 1
                                },
                            ],
                            art: [
                                {
                                    name: '>5,2',
                                    value: 5
                                },
                                {
                                    name: '5,00 - 5,2',
                                    value: 4
                                },
                                {
                                    name: '4,61 - 4,99',
                                    value: 3
                                },
                                {
                                    name: '4,31 - 4,60',
                                    value: 2
                                },
                                {
                                    name: '4,00 - 4,30',
                                    value: 1
                                },
                            ]

                        },
                        secondary_school: {
                            sport: [
                                {
                                    name: '>5,1',
                                    value: 5
                                },
                                {
                                    name: '5,00 - 5,10',
                                    value: 4
                                },
                                {
                                    name: '4,61 - 4,99',
                                    value: 3
                                },
                                {
                                    name: '4,31 - 4,60',
                                    value: 2
                                },
                                {
                                    name: '4,00 - 4,30',
                                    value: 1
                                },
                            ],
                            social: [
                                {
                                    name: '>5,1',
                                    value: 5
                                },
                                {
                                    name: '5,00 - 5,10',
                                    value: 4
                                },
                                {
                                    name: '4,61 - 4,99',
                                    value: 3
                                },
                                {
                                    name: '4,31 - 4,60',
                                    value: 2
                                },
                                {
                                    name: '4,00 - 4,30',
                                    value: 1
                                },
                            ],
                            science: [
                                {
                                    name: '>5,2',
                                    value: 5
                                },
                                {
                                    name: '5,00 - 5,20',
                                    value: 4
                                },
                                {
                                    name: '4,81 - 4,99',
                                    value: 3
                                },
                                {
                                    name: '4,51 - 4,80',
                                    value: 2
                                },
                                {
                                    name: '4,25 - 4,50',
                                    value: 1
                                },
                            ],
                            art: [
                                {
                                    name: '>5,1',
                                    value: 5
                                },
                                {
                                    name: '5,00 - 5,10',
                                    value: 4
                                },
                                {
                                    name: '4,61 - 4,99',
                                    value: 3
                                },
                                {
                                    name: '4,31 - 4,60',
                                    value: 2
                                },
                                {
                                    name: '4,00 - 4,30',
                                    value: 1
                                },
                            ]

                        },
                    }
                    ,

                    model: 'average',
                    stage: 'formStudent.student.average'
                },
                behavior: {
                    type: 'radio',
                    title: 'Ocena z zachowania',
                    subtitle: 'w roku szkolnym 2023/2024',
                    mandatory: '*',
                    options: [
                        {
                            name: 'wzorowe',
                            value: 'wzorowe'
                        },
                        {
                            name: 'bardzo dobre',
                            value: 'bardzo dobre'
                        },
                        {
                            name: 'dobre',
                            value: 'dobre'
                        }
                    ],
                    stage: 'formStudent.student.behavior'
                },
            },
            form: {
                category: null,
                average: null,
                behavior: null,
            }
        }
    },
    components: {
        RadioInput,
        'vue-select': VueNextSelect,
    },
    computed: {
        get_type() {
            return this.$store.getters.getValuByPath('formStudent.application.type');
        },
        averageError() {
            const error = this.$store.getters.getError('student.average')
            return error ? error[0] : null;
        }
    },
    watch: {
        form: {
            handler(newValue) {
                this.$emit('update:modelValue', newValue)
            },
            deep: true
        },
        'form.average'(newVal) {
            this.updateFormValue('formStudent.student.average', newVal);
            this.$store.commit('removeError', 'formStudent.student.average')
        }
    },
    methods: {
    },
};
</script>
