<template>
    <main-layout>
        <div class="-pt-nav">
            <div class="container background__icons-sm -pt-4 -pb-2 -pb-sm-12 -pt-sm-8 d-flex justify-content-center">
                <section-layout class="section-news__items col-sm-12">
                    <div class="row align-items-center">
                        <div class="col-md-7" v-if="application">
                            <img class="w-100" :src="application.img" alt="">
                        </div>
                        <div class="col-md-17 " v-if="application">
                            <div v-if="application.type != 'organization'" class="text-capitalize">Kategoria: {{
                                application.category
                            }}</div>
                            <div class="heading-32 color-blue mb-2">
                                {{ application.application_name }}
                                <span v-if="application.class">
                                    - {{ application.class }}. klasa
                                </span>
                            </div>
                            <div class="text-18 color-blue">Miejscowość: {{ application.city }} </div>
                        </div>
                        <div class="col-24" v-if="application">
                            <div class="line-separator my-5"></div>
                        </div>
                        <div class="col-24">

                            <div class="section-news__content mb-3" v-if="!status">
                                <div class="heading-32 color-blue text-center mb-5">Potwierdź oddanie głosu</div>
                                <p class="mb-3">
                                    Aby oddać głos na wybraną osobę, wpisz kod weryfikacyjny, który znajdzieszw
                                    przesłanej
                                    do
                                    ciebie wiadomości e-mail.
                                </p>
                                <label for="test-text" class="form-label text-18 form__title">
                                    Kod weryfikacyjny <span class="form__label form__label--red">*</span>
                                </label>
                                <div class="mb-4">
                                    <input id="test-text" placeholder="Kod weryfikacyjny"
                                        class="form-control form__textarea" v-model="test">

                                    <div class="text-danger" v-if="error">
                                        {{ error }}
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start -pb-6">
                                    <button @clic="sendForm" class="button__red form__btn" @click="sendForm">Dodaj
                                        głos<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></button>
                                </div>
                            </div>

                            <div class="section-news__content mb-3" v-if="status == 'success'">
                                <div class="heading-32 color-blue text-center mb-5">Dziękujemy.<br>Twój głos został oddany.</div>
                                <div class="modal-body text-center mt-3">
                                    <router-link :to="{ name: 'home' }" class="modal__extra-link text-16 form__title">
                                        Wróć do strony głównej
                                    </router-link>
                                </div>
                            </div>
                            <div class="section-news__content mb-3" v-if="status == 'error-422'">
                                <div class="heading-32 color-blue text-center mb-5">{{errors}}</div>
                                <div class="modal-body text-center mt-3">
                                    <router-link :to="{ name: 'home' }" class="modal__extra-link text-16 form__title">
                                        Wróć do strony głównej
                                    </router-link>
                                </div>
                            </div>
                            <div class="section-news__content mb-3" v-if="status == 'error-server'">
                                <div class="heading-32 color-blue text-center mb-5">Nieoczekiwany błąd serwera</div>
                                <p>Skontaktuj się z organizatorem konkursu przez formularz kontaktowy.</p>
                                <div class="modal-body text-center mt-3">
                                    <router-link :to="{ name: 'home' }" class="modal__extra-link text-16 form__title">
                                        Wróć do strony głównej
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section-layout>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import axios from "axios";

// Sections

export default {
    components: {
        MainLayout,
    },
    methods: {
        async sendForm() {
            const form = {
                id: this.id,
                test: this.test
            }

            axios
                .post(process.env.VUE_APP_API_BASE_URL + "vote-confirmation/" + this.id, {
                    ...form,
                })
                .then((response) => {
                    if (response.status == 200 && response.data.status == 'success') {
                        this.status = 'success'
                        this.message = 'Dziękujemy. Tówj głos został zakceptowny.'
                    } else {
                        this.error = "Błąd serwra"
                    }
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        this.error = error.response.data.errors.test[0];
                    } else if (error.response.data.status) {
                        this.error = error.response.data.message;
                    } else {
                        this.error = "Błąd serwra"
                    }
                });
        },
    },
    computed: {
    },
    watch: {
    },
    data() {
        return {
            status: null,
            test: null,
            post: {
            },
            errors: null,
            error: null,
            id: null,
            message: null,
            application: null,
        }
    },
    beforeRouteEnter(to, from, next) {
        axios
            .all([
                axios({
                    method: "get",
                    url: process.env.VUE_APP_API_BASE_URL + "vote-confirmation/" + to.params.id,
                }),
            ])
            .then(
                axios.spread((postsResponse) => {
                    console.log(postsResponse);
                    next((vm) => {
                        if (postsResponse.data.application) {
                            vm.application = postsResponse.data.application;
                        }
                        if (postsResponse.data.status == 'to-verified') {
                            vm.id = to.params.id;
                        } else if (postsResponse.data.status == 'verified') {
                            vm.status = 'success'
                            vm.message = postsResponse.data.message
                        }
                    });
                })
            )
            .catch((error) => {
                next((vm) => {
                    console.log(error)
                    if (error.response.data.application) {
                        vm.application = error.response.data.application;
                    }

                    if (error.response.status == 422) {
                        vm.errors = error.response.data.message
                        vm.status = 'error-422'
                    }else{
                        vm.status = 'error-server'
                    }

                    
                });
            });
    },

};
</script>
