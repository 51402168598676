<template>
    <div class="form-data">
        <textarea-ianput class="form__title text-18" v-model="form" :data="inputs.student_characterization" :errors="errors" />
    </div>
</template>

<script>
import TextareaIanput from "@/components/inputs/TextareaIanput.vue";


export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],
    data() {
        return {
            inputs: {
                student_characterization: {
                    type: 'textarea',
                    title: 'Krótka charakterystyka uczestnika konkursu ',
                    placeholder: 'Krótka charakterystyka uczestnika konkursu ',
                    tooltip: 'Należy podać informacje opisujące uczestnika konkursu np. opis osobowości, uzdolnienia, cechy wyróżniające, sukcesy, zainteresowania.',
                    subtitle: null,
                    mandatory: '*',
                    model: 'student_characterization',
                    stage: 'formStudent.student.characterization'
                },
            },
            form: ''
        }
    },
    components: {
        TextareaIanput
    },
    computed: {
    },
    watch: {
        form: {
            handler(newValue) {
                this.$emit('update:modelValue', newValue)
            },
            deep: true
        }
    },
    methods: {
    },
};
</script>
