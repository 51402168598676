<template>
    <router-view />
</template>

<script>
import { event } from "vue-gtag";
import axios from "axios";

export default {
    name: "App",
    components: {},
    created() {
        event("conversion", {
            allow_custom_scripts: true,
            send_to: "DC-9648695/invmedia/lacia00+standard",
        });
    },
    mounted() {
         axios
            .get(process.env.VUE_APP_API_BASE_URL  + 'allowed' )
            .then((response) => {
                console.log(response.data)
                this.$store.commit('setStage', response.data.stage)
                this.$store.commit('showFormII', response.data.showFormII)
                if(!response.data.allow){
                    this.$store.commit('turnOffapplication')
                }else{
                    this.$store.commit('turnOnapplication')
                }

                if(!response.data.votes){
                    this.$store.commit('turnOffvotes')
                }else{
                    this.$store.commit('turnOnvotes')
                }

                if(!response.data.finalist){
                    this.$store.commit('turnOffFinalist')
                }else{
                    this.$store.commit('turnOnFinalist')
                }

                if(!response.data.showApplication){
                    this.$store.commit('turnOffShowApplication')
                }else{
                    this.$store.commit('turnOnShowApplication')
                }
                
            })
            .catch((error) => {
            console.log(error)
            });
    },
};
</script>

<style>
:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
  
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.85s ease;
}


.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
