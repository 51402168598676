<template>
    <main-layout>
        <div class="-pt-nav background__pg-about  -pb-xs1-4 -pb-sm-12">
            <SectionHeader />
            <div class="container background__icons-big">
                <SectionParticipants />
                <SectionSteps />
            </div>
            <SectionPrize />
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";

// Sections
import SectionHeader from "@/components/sections/SectionHeader";
import SectionParticipants from "@/components/sections/SectionParticipants";
import SectionSteps from "@/components/sections/SectionSteps";
import SectionPrize from "@/components/sections/SectionPrize";

export default {
    components: {
        MainLayout,
        SectionHeader,
        SectionParticipants,
        SectionSteps,
        SectionPrize,
    },
    computed: {
    }
};
</script>
