import { createStore } from 'vuex'

export default createStore({
  state: {
    stage: null,
    showFormII: null,
    applicationActive: false,
    loteryActive: false,
    votesActive: false,
    finalistActive: false,
    showApplication: false,
    errors: [],
    formStudent: {
      application: {
        type: null,
        agreement_1: null,
        agreement_2: null,
        agreement_3: null,
        agreement_4: null,
        agreement_5: null,
      },
      person: {
        name: null,
        surname: null,
        workplace: null,
        phone: null,
        email: null,
        address_select: null,
        address: {},
        contact_address_select: null,
        contact_address: {}
      },
      student: {
        name: null,
        surname: null,
        birth_date: null,
        average: null,
        category: null,
        behavior: null,
        class: null,
        phone: null,
        email: null,
        student_board_select: null,
        desc: null,
        address: {},
        contact_address_select: null,
        contact_address: {},
        characterization: null,
        achievements: null,
        most_achievements: [
          { name: null }
        ],
        student_board: null,
        social_activity: null,
        social_activity_select: null,
        how_know: {},
        person_reporting: null
      }
    },
    formOrganization: {
      application: {
        type: 'organization',
        agreement_1: null,
        agreement_2: null,
        agreement_3: null,
        agreement_4: null,
      },
      person: {
        name: null,
        surname: null,
        workplace: null,
        phone: null,
        email: null,
        address_select: null,
        address: {},
        contact_address_select: null,
        contact_address: {}
      },
      organization: {
        organization_type: null,
        name: null,
        nip: null,
        regon_number: null,
        krs: null,
        phone: null,
        website: null,
        additional_data: null,
        operating_range: null,
        organization_desc: null,
        address: {},
        contact_address_select: null,
        contact_address: {},
      },
      project: {
        category: null,
        name: null,
        geographic_coverage: null,
        date_start: null,
        date_end: '',
        budget: [{
          name: null,
          value: null,
        }],
        desc: null,
        purpose_project: null,
        recipients_project: null,
        continuing_project: null,
        continuing_project_desc: null,
        other_entities_select: null,
        other_entities: [],
      }
    },
    contactForm: {
      name: null,
      email: null,
      text: null,
      agreement_1: null,
    },
    formOrganizationStep2: {
      application: {
        type: 'organization',
        agreement_1: null,
        agreement_2: null,
        agreement_3: null,
        agreement_4: null,
        agreement_5: null,
        agreement_6: null,
        agreement_7: null,
        agreement_8: null,
        agreement_9: null,
        youtube_link: null,
      },
      person: {
        name: null,
        surname: null,
        workplace: null,
        phone: null,
        email: null,
        address_select: null,
        address: {},
        contact_address_select: null,
        contact_address: {}
      },
      organization: {
        name: null,
        address: {},
        contact_address_select: null,
        contact_address: {},
      },
      project: {
        category: null,
        name: null,
        city: null,
        desc: null,
        budget_destiny: null,
        recipients_project: null,
      }
    },
    formStudentStep2: {
      application: {
        type: null,
        agreement_1: null,
        agreement_2: null,
        agreement_3: null,
        agreement_4: null,
        agreement_5: null,
        agreement_6: null,
        agreement_7: null,
        agreement_8: null,
        agreement_9: null,
        youtube_link: null,
      },
      person: {
        name: null,
        surname: null,
        workplace: null,
        phone: null,
        email: null,
        address_select: null,
        address: {},
        contact_address_select: null,
        contact_address: {}
      },
      student: {
        name: null,
        surname: null,
        birth_date: null,
        class: null,
        phone: null,
        email: null,
        address: {},
        contact_address_select: null,
        contact_address: {},
        category: null
      },
      project: {
        category: null,
        characterization: null,
        scholarship_plan: null,
        budget_destiny: null,
      }
    },
    files: {
      image: null,
      statement: null,
      power_attorney: null,
    }
  },
  getters: {
    getError: (state) => (value) => {
      return state.errors[value];
    },
    getValuByPath: (state) => (path) => {

      var paths = path.split('.')
        , current = state
        , i;

      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] == undefined) {
          return undefined;
        } else {
          // console.log(i, current[paths[i]])
          current = current[paths[i]];
        }
      }
      return current;
    }
  },
  mutations: {
    setStage(state, stage){
      state.stage = stage;
    },
    showFormII(state, showFormII){
      state.showFormII = showFormII;
    },
    turnOffapplication(state) {
      state.applicationActive = false
    },
    turnOffFinalist(state) {
      state.finalistActive = false
    },
    turnOffShowApplication(state) {
      state.showApplication = false
    },
    turnOnFinalist(state) {
      state.finalistActive = true
    },
    turnOnShowApplication(state) {
      state.showApplication = true
    },
    turnOffvotes(state) {
      state.votesActive = false
    },
    turnOnvotes(state) {
      state.votesActive = true
    },
    turnOnapplication(state) {
      state.applicationActive = true
    },
    removeError(state, path) {
      // console.log(path)
      const value = path.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '')
      if (value in state.errors) {
        delete state.errors[value];
      }
    },
    removeAllErrors(state) {
      state.errors = [];
    },
    updateStudentForm(state, newVal) {
      var stack = newVal.name.split('.');
      while (stack.length > 1) {
        state = state[stack.shift()];
      }
      state[stack.shift()] = newVal.value;
    },
    setErrors(stage, value) {
      stage.errors = value;
    },
    resetFormStudent(state) {
      state.formStudent = {
        application: {
          type: null,
          agreement_1: null,
          agreement_2: null,
          agreement_3: null,
          agreement_4: null,
          agreement_5: null,
        },
        person: {
          name: null,
          surname: null,
          workplace: null,
          phone: null,
          email: null,
          address_select: null,
          address: {},
          contact_address_select: null,
          contact_address: {}
        },
        student: {
          name: null,
          surname: null,
          birth_date: null,
          average: null,
          category: null,
          behavior: null,
          class: null,
          phone: null,
          email: null,
          student_board_select: null,
          desc: null,
          address: {},
          contact_address_select: null,
          contact_address: {},
          characterization: null,
          achievements: null,
          most_achievements: [
            { name: null }
          ],
          student_board: null,
          social_activity: null,
          social_activity_select: null,
          how_know: {},
          person_reporting: null
        }
      }

    },
    resetFormOrganization(state) {
      state.formOrganization = {
        application: {
          type: 'organization',
          agreement_1: null,
          agreement_2: null,
          agreement_3: null,
          agreement_4: null,
        },
        person: {
          name: null,
          surname: null,
          workplace: null,
          phone: null,
          email: null,
          address_select: null,
          address: {},
          contact_address_select: null,
          contact_address: {}
        },
        organization: {
          organization_type: null,
          name: null,
          nip: null,
          regon_number: null,
          krs: null,
          phone: null,
          website: null,
          additional_data: null,
          operating_range: null,
          organization_desc: null,
          address: {},
          contact_address_select: null,
          contact_address: {},
        },
        project: {
          category: null,
          name: null,
          geographic_coverage: null,
          date_start: null,
          date_end: '',
          budget: [{
            name: null,
            value: null,
          }],
          desc: null,
          purpose_project: null,
          recipients_project: null,
          continuing_project: null,
          continuing_project_desc: null,
          other_entities_select: null,
          other_entities: [],
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
