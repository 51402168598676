<template>
    <main-layout>
        <div class="-pt-nav">
            <div class="background__icons-sm">
                <div class="container -pt-4 -pt-sm-8">
                    <h1 class="heading-48 form__title d-flex justify-content-center">
                        Formularz kontaktowy
                    </h1>
                    <ContactForm/>
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import ContactForm from "@/components/forms/ContactForm.vue";


export default {
    components: {
        MainLayout,
        ContactForm,
        
    },
    computed: {
    }
};
</script>
