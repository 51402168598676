<template>
    <section-layout class="section-news d-flex justify-content-center" v-if="posts.length">
        <div class="section-news__content col-sm-16 -pt-4 -pt-sm-0">
            <p class="heading-40 section-news__title color-blue text-center">Aktualności</p>
            <div class="section-news__items">
                <div class="section-news__item" v-for="(post, index) in posts" :key="'post' + index">
                    <p class="text-16">{{ post.created_at_format }}</p>
                    <router-link :to="{ name: 'newsItem', params: { id: post.id } }">
                        <p class="heading-18 section-news__item-title color-blue cur-pointer">{{ post.title }}</p>
                        <p class="text-16 cur-pointer">{{ post.short_text }}</p>
                    </router-link>
                </div>
                <div class="d-flex justify-content-center" v-if="posts.length == 3">
                    <router-link :to="{ name: 'news' }" class="button__red">Zobacz wszystkie</router-link>
                </div>
            </div>
        </div>

    </section-layout>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            posts: []
        };
    },
    mounted() {
        axios
            .all([
                axios({
                    method: "get",
                    url: process.env.VUE_APP_API_BASE_URL + "news",
                }),
            ])
            .then(
                axios.spread((postsResponse) => {
                    // console.log(postsResponse);
                    if (postsResponse.data.length > 3) {
                        this.posts = postsResponse.data.slice(0, 2)
                    } else {
                        this.posts = postsResponse.data
                    }
                })
            )
            .catch((error) => {
                this.errors = error;
            });
    },
    methods: {
        getDate(date) {
            const newDate = new Date(date);
            const day = newDate.getDay() < 10 ? '0' + newDate.getDay() : newDate.getDay();
            const month = newDate.getMonth() < 10 ? '0' + newDate.getMonth() : newDate.getMonth();
            return day + '.' + month + '.' + newDate.getFullYear();
        }
    },
    components: {
    },
    computed: {
    },
};
</script>
