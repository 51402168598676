<script>
export default {
  props: ['modelValue', 'data', 'index'],
  emits: ['update:modelValue'],
  data() {
    return {
      value: null
    }
  },
  watch: {
    value(newVal) {
      this.$emit('update:modelValue', newVal);
      if (this.data.stage) {
        this.updateFormValue(this.data.stage, newVal)
        this.$store.commit('removeError', this.data.stage)
      }
      if (this.data.stageIndex) {
        this.$store.commit('removeError', this.data.stageIndex.replace('stageIndex', this.index))
      }
    }
  },
  computed: {
    error() {
      if (this.data.stage) {
        const path = this.data.stage.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '')
        const error = this.$store.getters.getError(path);
        if (error && error.length)
          return error[0]

        return null
      }
      if (this.data.stageIndex) {
        const path = this.data.stageIndex.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '').replace('stageIndex', this.index)
        const error = this.$store.getters.getError(path);
        if (error && error.length)
          return error[0]

        return null
      }
      return null
    }
  },
  mounted() {
    if(this.data.autoload && this.$store.getters.getValuByPath(this.data.stage)){
      this.value = this.$store.getters.getValuByPath(this.data.stage);
    }
  },
  methods: {
    updateValue(event) {
      const value = event.target.value
      if(!this.data.max){
        this.value = value;
        return
      }

      if (String(value).length <= this.data.max) {
        this.value = value
      }
      this.$forceUpdate()
    }
  }
}
</script>

<template>
  <div class="">
    <label v-if="data.title" :for="data.model" class="form-label text-18 form__title">
      {{ data.title }} 
      <PopperPopper v-if="data.tooltip" :hover="true" :arrow="true" :content="data.tooltip">
        <button class="form__tooltip"></button>
      </PopperPopper>
      <span class="form__label description-18">{{ data.extrainfo }}</span>
      <span class="form__label form__label--red">{{ data.mandatory }}</span>
      <span class="form__label form__label--red" v-if="index == 0">{{ data.mandatoryAchievements }}</span>
    </label>
    <input type="number" :id="data.model" :placeholder="data.placeholder" class="form-control form__textarea inputNumber"
      :class="{ 'form__textarea-error': error }" @input="updateValue"  :value="value">
    <div class="text-danger" v-if="error">
      {{ error }}
    </div>
  </div>
</template>