<template>
    <div class="form-data">
        <!-- Nazwa organizacji -->
        <p class="heading-24 text-center form__title -pt-4">
            Nazwa organizacji
        </p>

        <div class="col-md-12">
            <text-input :data="inputs.name" />
        </div>

        <div class="col-md-16 offset-md-4 mt-4 text-danger text-center">
            UWAGA. Jeśli dane w tej części uległy zmianie w porównaniu z danymi, które są podane poniżej, należy je
            poprawić (dane, które się wyświetlają, zostały podane w I etapie).
        </div>

        <!-- siedziba -->
        <p class="heading-24 text-center form__title -pt-4">Adres siedziby organizacji</p>
        <address-form stage-name="formOrganizationStep2.organization.address" />

        <radio-input class="d-flex justify-content-center" v-model="form.contact_address_select"
            :data="inputs.contact_address_select" />
        <address-form v-if="form.contact_address_select == 'yes'"
            stage-name="formOrganizationStep2.organization.contact_address" />

    </div>
</template>

<script>
import RadioInput from "@/components/inputs/RadioInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import AddressForm from "@/components/forms/AddressFormAutoload.vue";

export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],

    components: {
        RadioInput,
        TextInput,
        AddressForm,
    },
    computed: {
    },
    methods: {
    },
    data() {
        return {
            form: {
                contact_address_select: null
            },
            inputs: {
                name: {
                    type: 'text',
                    title: 'Nazwa organizacji',
                    placeholder: 'Nazwa organizacji',
                    subtitle: null,
                    mandatory: '*',
                    model: 'organization_name',
                    autoload: true,
                    stage: 'formOrganizationStep2.organization.name'
                },
                contact_address_select: {
                    type: 'radio',
                    title: 'Adres korespondencyjny',
                    autoload: true,
                    subtitle: 'Należy wybrać jedną z dwóch opcji:',
                    // flexRow: true,
                    value: 'formOrganizationStep2.organization.contact_address.city',
                    options: [
                        {
                            name: 'taki jak adres organizacji',
                            value: 'no'
                        },
                        {
                            name: 'inny',
                            value: 'yes'
                        }
                    ],
                    model: 'contact_address_select_organization',
                    stage: 'formOrganizationStep2.organization.contact_address_select',
                },
            },
        }
    },
};
</script>
