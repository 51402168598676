<template>
    <div class="form-data">
        <div class="row">
            <div class="col-md-16 offset-md-4 form__pt-16">
                <div class="row align-items-center">
                    <div class="col-md-14">
                        <p class="form__title heading-18">Załączniki do zgłoszenia w II etapie</p>
                    </div>
                    <div class="col-md-10">
                        <a class="button__red form__btn" target="_blank" href="/orgnizacje_zalacznik_nr_2.pdf" @click="sendForm" :disabled="sendFormLoader">
                            <div v-if="sendFormLoader">
                                <svg style="width: 20px; height: 20px;" version="1.1" id="L9"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0"
                                    xml:space="preserve">
                                    <path fill="#fff"
                                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                        <animateTransform attributeName="transform" attributeType="XML" type="rotate"
                                            dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                    </path>
                                </svg>
                            </div>
                            <span>Pobierz</span>
                            <img src="@/assets/right-btn.svg" alt="" class="button__arrow" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Osoba zgłaszająca -->
        <p class="heading-24 text-center form__title -pt-4 form__pb-8">Osoba zgłaszająca organizację do konkursu</p>
        <div class="d-flex justify-content-center">
            <p class="col-12 text-center -pb-4 form__description">Osoba upoważniona do reprezentowania Organizacji i zaciągania zobowiązań
                finansowych w
                jej imieniu.</p>
        </div>
        <div class="row form__gap-24 form__pt-24">
            <div class="col-md-12">
                <text-input :data="inputs.name" />
            </div>
            <div class="col-md-12">
                <text-input :data="inputs.surname" />
            </div>
            <div class="col-md-8">
                <text-input :data="inputs.workplace" />
            </div>
            <div class="col-md-8">
                <text-input-number :data="inputs.phone" />
            </div>
            <div class="col-md-8">
                <text-input :data="inputs.email" />
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";
import TextInputNumber from "@/components/inputs/TextInputNumber.vue";

export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],

    components: {
        TextInput,
        TextInputNumber,
    },
    computed: {
    },
    methods: {
    },
    data() {
        return {
            form: {
                contact_address_select: null
            },
            inputs: {
                name: {
                    type: 'text',
                    title: 'Imię/imiona',
                    placeholder: 'Imię/imiona',
                    subtitle: null,
                    mandatory: '*',
                    model: 'organization_reporting_name',
                    stage: 'formOrganizationStep2.person.name',
                    autoload: true
                },
                surname: {
                    type: 'text',
                    title: 'Nazwisko',
                    placeholder: 'Nazwisko',
                    subtitle: null,
                    mandatory: '*',
                    model: 'organization_reporting_surname',
                    stage: 'formOrganizationStep2.person.surname',
                    autoload: true
                },
                workplace: {
                    type: 'text',
                    title: 'Stanowisko/pełniona funkcja',
                    placeholder: 'Stanowisko/pełniona funkcja',
                    subtitle: null,
                    mandatory: '*',
                    model: 'organization_reporting_workplace',
                    stage: 'formOrganizationStep2.person.workplace',
                    autoload: true
                },
                phone: {
                    type: 'text',
                    title: 'Nr telefonu',
                    placeholder: 'Nr telefonu',
                    subtitle: null,
                    mandatory: '*',
                    tooltip: 'Z tym numerem telefonu zostanie podjęta próba połączenia w razie potrzeby.',
                    model: 'organization_reporting_phone',
                    stage: 'formOrganizationStep2.person.phone',
                    autoload: true
                },
                email: {
                    type: 'text',
                    title: 'E-mail',
                    placeholder: 'E-mail',
                    subtitle: null,
                    mandatory: '*',
                    tooltip: 'Na ten adres zostanie wysłany wypełniony formularz.',
                    model: 'organization_reporting_email',
                    stage: 'formOrganizationStep2.person.email',
                    autoload: true
                },
            },
        }
    },
};
</script>

