<template>
    <div>
        <label v-if="data.title" :for="data.model" class="form-label-select">
            {{ data.title }} <span class="form__label form__label--red">{{ data.mandatory }}</span>
        </label>
        <div :class="{ 'vue-select__error': error }">
            <vue-select :close-on-select="true" :model-value="true" v-model="value" :options="data.options"
                :placeholder="data.placeholder" :label-by="data.label" :value-by="data.value">
                <template #label="{ selected }">
                    <template v-if="selected">
                        <div>{{ selected.name }}</div>
                    </template>
                </template>
            </vue-select>
        </div>
        <div class="text-danger" v-if="error">
            {{ error }}
        </div>
    </div>
</template>
<script>
import VueNextSelect from 'vue-next-select'

export default {
    props: ['modelValue', 'data', 'index'],
    emits: ['update:modelValue'],
    data() {
        return {
            value: null
        }
    },
    mounted() {
        if (this.data.autoload && this.$store.getters.getValuByPath(this.data.stage)) {
            this.value = this.$store.getters.getValuByPath(this.data.stage);
        }
    },
    components: { 'vue-select': VueNextSelect },
    watch: {
        value(newVal) {
            this.$emit('update:modelValue', newVal);
            if (this.data.stage) {
                this.updateFormValue(this.data.stage, newVal)
                this.$store.commit('removeError', this.data.stage)
            }
            if (this.data.stageIndex) {
                this.$store.commit('removeError', this.data.stageIndex.replace('stageIndex', this.index))
            }
        }
    },
    computed: {
        error() {
            if (this.data.stage) {
                const path = this.data.stage.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '')
                const error = this.$store.getters.getError(path);
                if (error && error.length)
                    return error[0]

                return null
            }
            if (this.data.stageIndex) {
                const path = this.data.stageIndex.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '').replace('stageIndex', this.index)
                const error = this.$store.getters.getError(path);
                if (error && error.length)
                    return error[0]

                return null
            }
            return null
        }
    },
}
</script>

