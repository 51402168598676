<template>
    <div class="form-data">
        <radio-input v-model.number="form.student_school_council_select" :data="inputs.student_school_council_select" />
        <radio-input v-model.number="form.student_class_self_government_select" :data="inputs.student_class_self_government_select" />
        <radio-input v-model.number="form.student_chairman_select" :data="inputs.student_chairman_select" />
        <radio-input v-model.number="form.social_activity_select" :data="inputs.social_activity_select" />


        <textarea-ianput v-show="form.social_activity_select == 'yes'" v-model="form.social_activity"
            :data="inputs.social_activity" />

    </div>
</template>

<script>
import RadioInput from "@/components/inputs/RadioInput.vue";
import TextareaIanput from "@/components/inputs/TextareaIanput.vue";


export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],
    data() {
        return {
            inputs: {
                student_school_council_select: {
                    type: 'radio',
                    title: 'Czy uczeń działa w samorządzie szkolnym?',
                    subtitle: null,
                    flexRow: true,
                    mandatory: '*',
                    options: [
                        {
                            name: 'tak',
                            value: 'yes'
                        },
                        {
                            name: 'nie',
                            value: 'no'
                        }
                    ],
                    model: 'student_school_council_select',
                    stage: 'formStudent.student.student_school_council_select'
                },
                student_class_self_government_select: {
                    type: 'radio',
                    title: 'Czy uczeń działa w samorządzie klasowym?',
                    subtitle: null,
                    flexRow: true,
                    mandatory: '*',
                    options: [
                        {
                            name: 'tak',
                            value: 'yes'
                        },
                        {
                            name: 'nie',
                            value: 'no'
                        }
                    ],
                    model: 'student_class_self_government_select',
                    stage: 'formStudent.student.student_class_self_government_select'
                },
                student_chairman_select: {
                    type: 'radio',
                    title: 'Czy uczeń jest przewodniczącym samorządu?',
                    subtitle: null,
                    flexRow: true,
                    mandatory: '*',
                    options: [
                        {
                            name: 'tak',
                            value: 'yes'
                        },
                        {
                            name: 'nie',
                            value: 'no'
                        }
                    ],
                    model: 'student_chairman_select',
                    stage: 'formStudent.student.student_chairman_select'
                },
                social_activity_select: {
                    type: 'radio',
                    title: 'Czy uczeń jest zaangażowany w stałe działania wolontariackie, które nie zostały opisane powyżej, lub chce pochwalić się innymi aktywnościami, które nie wpisują się w powyższe kategorie?',
                    subtitle: 'Wskaż dodatkową aktywność społeczną (regularną lub stałą), która nie została podana jako szczególne osiągnięcie. Może to być: zbiórka charytatywna, działania na rzecz kultury, działania na rzecz promowania patriotyzmu, działania na rzecz społeczności lokalnych.',
                    flexRow: true,
                    mandatory: '*',
                    options: [
                        {
                            name: 'tak',
                            value: 'yes'
                        },
                        {
                            name: 'nie',
                            value: 'no'
                        }
                    ],
                    model: 'social_activity_select',
                    stage: 'formStudent.student.social_activity_select'
                },
                student_board: {
                    type: 'textarea',
                    title: 'W jakim samorządzie działa uczestnik konkursu?',
                    placeholder: 'W jakim samorządzie działa uczestnik konkursu?',
                    subtitle: null,
                    mandatory: '*',
                    tooltip: 'Należy opisać, w jakim samorządzie działa i jakie są jego zadania.',
                    model: 'student_board',
                    stage: 'formStudent.student.student_board'
                },
                social_activity: {
                    type: 'textarea',
                    title: 'Opis zaangażowania ucznia',
                    placeholder: 'Opis zaangażowania ucznia',
                    subtitle: null,
                    mandatory: '*',
                    tooltip: 'Należy podać rodzaj działalności społecznej, rolę ucznia, na czym polegało jego zaangażowanie oraz częstotliwość działalności.',
                    model: 'social_activity',
                    stage: 'formStudent.student.social_activity'
                },
            },
            form: {
                student_school_council_select: null,
                student_class_self_government_select: null,
                student_chairman_select: null,
                social_activity_select: '',
                social_activity: ''
            }
        }
    },
    components: {
        RadioInput,
        TextareaIanput
    },
    computed: {
    },
    watch: {
        form: {
            handler(newValue) {
                this.$emit('update:modelValue', newValue)
            },
            deep: true
        }
    },
    methods: {
    },
};
</script>
