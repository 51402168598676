<template>
  <main-layout>
    <div class="-pt-nav min-height-footer">
      <div class="container background__icons-sm -pt-sm-8 -pb-xs1-4 -pb-sm-12">
        <section-layout class="section-news d-flex justify-content-center">
          <div class="section-news__content -pt-4 -pt-sm-0 col-24 col-sm-16">
            <p class="heading-40 color-blue text-center -pb-4 -pb-sm-8">Aktualności</p>
            <p v-if="displayedPosts.length == 0" class="heading-40 section-news__title color-blue text-center">Już
              wkrótce
            </p>
            <div v-if="displayedPosts.length" class="section-news__items">
              <div v-for="(post, index) in displayedPosts" :key="'post' + index" class="section-news__item">
                <p class="text-16">{{ post.created_at_format }}</p>
                <router-link :to="{ name: 'newsItem', params: { id: post.id } }">
                  <p class="heading-18 section-news__item-title color-blue cur-pointer">{{ post.title }}</p>
                  <p class="text-16 cur-pointer">{{ post.short_text }}</p>
                </router-link>
              </div>
              <div class="d-flex justify-content-center">
                <!-- Paginacja -->
                <div class="container d-flex justify-content-center">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination d-flex">

                      <li class="page-item">
                        <button type="button" class="page-link" v-if="page != 1" @click="page--"> <img
                            src="@/assets/arrow-prev.svg" alt="" class="" /> </button>
                      </li>
                      <li class="page-item d-flex">
                        <button type="button" class="page-link"
                          v-for="(pageNumber, index) in pages.slice(page - 1, page + 2)" :key="'paginate' + index"
                          @click="page = pageNumber"> {{ pageNumber }} </button>
                      </li>
                      <li class="page-item">
                        <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> <img
                            src="@/assets/arrow-next.svg" alt="" class="" /> </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section-layout>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import axios from "axios";

// Sections

export default {
  components: {
    MainLayout,
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.posts.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(posts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return posts.slice(from, to);
    }
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.posts);
    }
  },
  watch: {
    posts() {
      this.setPages();
    }
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + '...';
    }
  },
  data() {
    return {
      posts: [],
      page: 1,
      perPage: 5,
      pages: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    axios
      .all([
        axios({
          method: "get",
          url: process.env.VUE_APP_API_BASE_URL + "news",
        }),
      ])
      .then(
        axios.spread((postsResponse) => {
          // console.log(postsResponse);
          next((vm) => {
            vm.posts = postsResponse.data;
          });
        })
      )
      .catch((error) => {
        this.errors = error;
      });
  },

};
</script>
