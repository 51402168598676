<template>
    <div class="row form__gap-24 form__pt-24">
        <div class="col-md-12">
            <text-input v-model="form.city" :data="inputs.city"></text-input>
        </div>

        <div class="col-md-12">
            <text-input v-model="form.street" :data="inputs.street"></text-input>
        </div>

        <div class="col-md-12">
            <text-input v-model="form.number" :data="inputs.number"></text-input>
        </div>

        <div class="col-md-12">
            <text-input v-model="form.postal_code" :data="inputs.postal_code"></text-input>
        </div>

        <div class="col-md-12">
            <text-input v-model="form.community" :data="inputs.community"></text-input>
        </div>

        <div class="col-md-12">
            <select-field class="form__label form__label--blue" v-model="form.voivodeship"
                :data="inputs.voivodeship"></select-field>
        </div>
    </div>
</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";
import SelectField from "@/components/inputs/SelectField.vue";

export default {
    data() {
        return {
            form: {
                city: '',
                street: '',
                number: '',
                postal_code: '',
                community: '',
                voivodeship: ''
            },
            inputs: {
                city: {
                    type: 'text',
                    title: 'Miejscowość',
                    placeholder: 'Miejscowość',
                    subtitle: null,
                    mandatory: '*',
                    model: 'city',
                    stage: this.stageName + '.city'
                },
                street: {
                    type: 'text',
                    title: 'Ulica',
                    placeholder: 'Ulica',
                    mandatory: '*',
                    subtitle: null,
                    model: 'street',
                    stage: this.stageName + '.street'
                },
                number: {
                    type: 'text',
                    title: 'Nr domu/mieszkania',
                    placeholder: 'Nr domu/mieszkania',
                    subtitle: null,
                    mandatory: '*',
                    model: 'number',
                    stage: this.stageName + '.number'
                },
                postal_code: {
                    type: 'text',
                    title: 'Kod pocztowy',
                    placeholder: 'Kod pocztowy',
                    subtitle: null,
                    mandatory: '*',
                    model: 'postal_code',
                    stage: this.stageName + '.postal_code'
                },
                community: {
                    type: 'text',
                    title: 'Gmina',
                    placeholder: 'Gmina',
                    subtitle: null,
                    mandatory: '*',
                    model: 'community',
                    stage: this.stageName + '.community'
                },
                voivodeship: {
                    type: 'select',
                    title: 'Województwo',
                    placeholder: 'Województwo',
                    subtitle: null,
                    mandatory: '*',
                    options: [
                        { name: "dolnośląskie" },
                        { name: "kujawsko-pomorskie" },
                        { name: "lubelskie" },
                        { name: "lubuskie" },
                        { name: "łódzkie" },
                        { name: "małopolskie" },
                        { name: "mazowieckie" },
                        { name: "opolskie" },
                        { name: "podkarpackie" },
                        { name: "podlaskie" },
                        { name: "pomorskie" },
                        { name: "śląskie" },
                        { name: "świętokrzyskie" },
                        { name: "warmińsko-mazurskie" },
                        { name: "wielkopolskie" },
                        { name: "zachodniopomorskie" },
                    ],
                    label: 'name',
                    value: 'name',
                    model: 'voivodeship',
                    stage: this.stageName + '.voivodeship'
                }
            }
        }
    },
    components: {
        TextInput,
        SelectField,
    },
    watch: {
        form: {
            handler(newVal) {
                this.$emit('update:modelValue', newVal);
                if (this.stageName)
                    this.updateFormValue(this.stageName, newVal);
            },
            deep: true
        }
    },
    computed: {
    },
    props: ['modelValue', 'stageName']
};
</script>
