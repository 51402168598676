<template>
    <div>
        <div :class="{ 'd-sm-none': currentRouteName }">
            <SectionPatron />
        </div>
        <footer class="footer">
            <div class="container">
                <div class="footer__content row align-items-center justify-content-between">
                    <div class="footer__logo">
                        <SiteLogo />
                    </div>
                    <div class="footer__menu-content">
                        <FooterNavbar />
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import SiteLogo from "@/components/SiteLogo"
import FooterNavbar from "@/components/navbars/FooterNavbar"
import SectionPatron from "@/components/sections/SectionPatron.vue";

export default {
    components: {
        SiteLogo,
        FooterNavbar,
        SectionPatron
    },
    computed: {
        currentRouteName() {
            switch (this.$route.name) {
                case 'signUpStudent':
                    return true;

                case 'formStep2':
                    return true;

                default:
                    return false;
            }
        }
    }
}
</script>
