<template>
    <!-- <div class="header__height"></div> -->
    <div class="header__content d-flex justify-content-between">
        <header class="header">
            <div class="container">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-3"><SiteLogo /></div>
                    <div class="header__menu-content"><HeaderNavbar /></div>
                </div>     
            </div>
        </header>
        <div class="hamburger js-hamburger d-sm-none" @click="toggle">
            <div class="hamburger__lines"></div>
        </div>      
    </div>
</template>

<script>

import SiteLogo from "@/components/SiteLogo"
import HeaderNavbar from "@/components/navbars/HeaderNavbar"

export default {
    name: "App",
    components: {
        SiteLogo,
        HeaderNavbar,
    },
    methods: { 
        toggle () {
            document.querySelector('.js-menu-toggle').classList.toggle("menu--active")
            document.querySelector('.js-hamburger').classList.toggle("hamburger--active")
            document.querySelector(".main").classList.toggle("hidden");
        }
    },
    computed: {},
};

</script>