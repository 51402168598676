<template>
    <main-layout>
        <SectionThankYou />
    </main-layout>
    <!--ClickMeter.com page views tracking: Display CPL -->
    <VueScriptComponent
        script='<script type="text/javascript">window.ClickMeter_pixel_url = "//pixel.watch/4dg2";</script>'
    />
    <component
        :is="'script'"
        type="text/javascript"
        id="cmpixelscript"
        src="//s3.amazonaws.com/scripts-clickmeter-com/js/pixelNew.js"
    ></component>
    <noscript>
        <img height="0" width="0" alt="" src="http://pixel.watch/4dg2" />
    </noscript>
    <!--ClickMeter.com page views tracking: Mailing CPC -->
    <VueScriptComponent
        script='<script type="text/javascript">window.ClickMeter_pixel_url = "//pixel.watch/zhcx";</script>'
    />
    <component
        :is="'script'"
        type="text/javascript"
        id="cmpixelscript"
        src="//s3.amazonaws.com/scripts-clickmeter-com/js/pixelNew.js"
    ></component>
    <noscript>
        <img height="0" width="0" alt="" src="http://pixel.watch/zhcx" />
    </noscript>
    <!-- Offer Goal Conversion: Mlekpol_CPL -->
    <img src="https://redgroupspzoo.go2cloud.org/aff_goal?a=l&goal_id=189&adv_sub=IdFormularza" width="1" height="1" />
    <!-- // End Offer Goal Conversion -->
    <!-- Offer Conversion: Laciate_loteria_III-2022_659_AF -->
    <iframe scrolling="no" frameborder="0" width="1" height="1" style="display: none" :src="this.bedigital"></iframe>
    <!-- // End Offer Conversion -->
    <!-- (c) 2000-2022 Gemius SA version 2.0 / gT account: autoGtGde / Łaciate_Konkurs_Lead -->
    <component :is="'script'" type="text/javascript">
        (_gdeaq = window._gdeaq || []).push(["nc", "false"]); (_gdeaq = window._gdeaq || []).push(["cmp", "false"],
        ["gdpr_consent", ""]); (_gdeaq = window._gdeaq || []).push([ "pageView", "gde-default",
        "B3A7YiuMXAIbfWgUkv6kL3XnnM7Z.eRbxFIrNk2ac7n.R7", "my.adocean.pl",
        "aN8AaQ5YNynlaTJa.1iP.wCaE.Z.J0OBiULt9GfK6_P.97", ]);
    </component>
    <component :is="'script'" type="text/javascript" src="//gde-default.hit.gemius.pl/gdejs/xgde.js"></component>
    <iframe :src="this.tradedoubler" height="0px" width="0px" frameBorder="0"></iframe>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";

// Sections

import SectionThankYou from "@/components/sections/SectionThankYou";
import VueScriptComponent from "@/components/parts/VueScriptComponent.vue";

export default {
    props: ["orderId"],
    components: {
        MainLayout,
        VueScriptComponent,
        SectionThankYou,
    },
    data() {
        return {
            tradedoubler: `https://tbl.tradedoubler.com/report?organization=2113472&event=380561&leadNumber=${this.orderId}&type=iframe`,
            redgroupspzoo: `https://redgroupspzoo.go2cloud.org/aff_goal?a=l&goal_id=189&adv_sub=${this.orderId}`,
            bedigital: `https://bedigital.go2cloud.org/aff_l?offer_id=4209&adv_sub=${this.orderId}`,
        };
    },
};
</script>
