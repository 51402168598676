<template>
    <SiteHeader />
    <main class="main">
        <slot></slot>
    </main>
    <SiteFooter />
</template>

<script>
import SiteHeader from "@/components/SiteHeader"
import SiteFooter from "@/components/SiteFooter"

export default {

    components: {
        SiteHeader,
        SiteFooter,
    }
}
</script>