<template>
    <main-layout>
        <div class="-pt-nav">
            <div class="container background__icons-sm -pt-4 -pb-2 -pb-sm-12 -pt-sm-8 d-flex justify-content-center">
                <section-layout class="section-news__items col-sm-18">
                    <div class="section-news__content">
                        <p class="text-16">{{post.created_at_format}}</p>
                        <p class="heading-40 form__title color-blue -pb-4">{{post.title}}</p>
                        <div v-html="post.body" class="text-16 section-news__text"></div>
                        <div class="-pt-4 -pb-1 text-center">
                            <router-link :to="{ name: 'news' }" class="button__red">Powrót do aktualności</router-link>
                        </div>
                    </div>
                </section-layout>
            </div>
        </div>
    </main-layout>
</template>
  
<script>
import MainLayout from "@/layouts/MainLayout.vue";
import axios from "axios";

// Sections

export default {
    components: {
        MainLayout,
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    data() {
        return {
            post: {
            },
            errors: null,
        }
    },
    beforeRouteEnter(to, from, next) {
        axios
            .all([
                axios({
                    method: "get",
                    url: process.env.VUE_APP_API_BASE_URL + "news/" + to.params.id,
                }),
            ])
            .then(
                axios.spread((postsResponse) => {
                    // console.log(postsResponse);
                    next((vm) => {
                        vm.post = postsResponse.data;
                    });
                })
            )
            .catch((error) => {
                this.errors = error;
            });
    },

};
</script>
  