<script>
import { split } from 'lodash';


export default {
  props: ['modelValue', 'data', 'index'],
  emits: ['update:modelValue'],
  mounted() {
    
    if(this.data.autoload && this.$store.getters.getValuByPath(this.data.stage)){
      const dateArr = split(this.$store.getters.getValuByPath(this.data.stage), '/');
      this.value = new Date(parseInt(dateArr[2]), parseInt(dateArr[1]-1), parseInt(dateArr[0]));
    }
  },
  data() {
    return {
      value: null,
      dateFormat: (date) => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let dayToPrint = day < 10 ? "0" + day : day;
        let monthToPrint = month < 10 ? "0" + month : month;
        let yearToPrint = year;
        return `${dayToPrint}.${monthToPrint}.${yearToPrint}`;
      },
    }
  },
  watch: {
    value(newVal) {
      const date = new Date(newVal);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let dayToPrint = day < 10 ? "0" + day : day;
      let monthToPrint = month < 10 ? "0" + month : month;
      let yearToPrint = year;
      const dataReturn = `${dayToPrint}/${monthToPrint}/${yearToPrint}`;
      this.$emit('update:modelValue', dataReturn);
      if (this.data.stage)


        if (this.data.stage) {
          this.updateFormValue(this.data.stage, dataReturn)
          this.$store.commit('removeError', this.data.stage)
        }
    }
  },
  computed: {
    error() {
      if (this.data.stage) {
        const path = this.data.stage.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '')
        const error = this.$store.getters.getError(path);
        if (error && error.length)
          return error[0]

        return null
      }
      if (this.data.stageIndex) {
        const path = this.data.stageIndex.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '').replace('stageIndex', this.index)
        const error = this.$store.getters.getError(path);
        if (error && error.length)
          return error[0]

        return null
      }
      return null
    },
    minDate() {
      if (this.data.start) {
        const val = this.$store.getters.getValuByPath(this.data.start);
        if (!val || val == '') return null;

        const day = val.slice(0, 2),
          month = val.slice(3, 5),
          year = val.slice(6, 10);
        return new Date(year, month - 1, day);
      }
      return null
    },
    maxDate() {
      if (this.data.end) {
        const val = this.$store.getters.getValuByPath(this.data.end);
        if (!val || val == '') return null;

        const day = val.slice(0, 2),
          month = val.slice(3, 5),
          year = val.slice(6, 10);
        return new Date(year, month - 1, day);
      }
      return null
    }
  },
}
</script>

<template>
  <div class="">
    <label v-if="data.title" :for="data.model" class="form-label text-18 form__title">
      {{ data.title }} <span class="form__label description-18">{{ data.extrainfo }}</span>
      <span class="form__label form__label--red">{{ data.mandatory }}</span>
    </label>
    <DatepickerDate ref="datepicker" :modelValue="value" textInput
      :textInputOptions="{ format: 'dd.MM.yyyy', enterSubmit: true, tabSubmit: true }" :format="dateFormat"
      :previewFormat="dateFormat" :enableTimePicker="false" :minDate="minDate" :maxDate="maxDate"
      inputClassName="form__input text-uppercase form__input--datepicker" selectText="Wybierz datę" :cancelText='null'
      :format-locale="pl" locale="pl" placeholder="DD-MM-YYYY" @blur="blur" v-model="value"
      :class="{ 'dp__input-error': error }"></DatepickerDate>
    <div class="text-danger" v-if="error">
      {{ error }}
    </div>
  </div>
</template>