<template>
    <div class="container -pt-4 -pt-sm-8 -pb-4 -pb-sm-8">
        <div class="d-flex justify-content-center">
            <form form id="" action="" @submit.prevent="sendForm" class="form__row col-sm-18">
                <p class="heading-24 form__title -pb-4 text-center d-none d-sm-block">
                    Masz jakieś pytania? Zostaw nam wiadomość!
                </p>
                <div class="row form__gap-24">
                    <div class="col-sm-12">
                        <div class="">
                            <text-input :data="inputs.name" class="d-flex flex-column align-items-start" />
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="">
                            <text-input :data="inputs.email" class="d-flex flex-column align-items-start" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="">
                        <div class="">
                            <textarea-ianput :data="inputs.text" class="d-flex flex-column align-items-start" />
                        </div>
                    </div>
                </div>
                <label class="form__label form__label--checkbox mt-4" v-for="contactTerm of contactTerms"
                    :key="contactTerm.id">
                    <div class="d-flex">
                        <input class="form-check-input col-2 text-start"
                            :class="{ 'form-check-input__error': agreement_1_error }" v-model="agreement_1"
                            type="checkbox" id="flexCheckDefault" />
                        <div class="-pl-2">
                            <p v-if="!readMoreActivated[contactTerm.id]" class="text-start text-16 form__description">
                                <span class="form__label form__label--red">* </span> 
                                <span v-html="contactTerm.description.slice(0, 251)"></span>
                                <span
                                    v-if="!readMoreActivated[contactTerm.id] && contactTerm.description.length - 1 > 251"
                                    class="text-start text-16 heading-16 cur-pointer"
                                    @click.prevent="checkmarkExpand([contactTerm.id])">[rozwiń]</span>
                            </p>
                            <p v-if="readMoreActivated[contactTerm.id]" class="form__description">
                                <span v-html="contactTerm.description"></span>
                                <span class="form__checkmark-collapse text-start heading-16 cur-pointer"
                                    v-if="readMoreActivated[contactTerm.id]"
                                    @click.prevent="checkmarkCollapse([contactTerm.id])">[zwiń]</span>
                            </p>
                        </div>
                    </div>
                    <div class="text-danger -pl-5" v-if="agreement_1_error">
                        {{ agreement_1_error }}
                    </div>
                </label>
                <div class="form__error-terms" v-if="termsErrors.length > 0">Zaznaczone zgody są wymagane.</div>
                <div class="form__error-terms" v-if="errors.grecaptcha_token">Błąd walidacji recaptcha.</div>
                <div class="d-flex justify-content-center -pt-4">
                    <button type="submit" :disabled="this.submitting" class="button__red">Wyślij wiadomość</button>
                </div>

                <transition name="fade">
                    <modal-success v-if="showModal.show" @close-modal="() => showModal.show = false"
                        :type="showModal.type" />
                </transition>
            </form>
        </div>
    </div>
</template>

<script>
// import SendFormModal from "@/components/parts/SendFormModal";
import TextInput from "@/components/inputs/TextInput.vue";
import TextareaIanput from "@/components/inputs/TextareaIanput.vue";
import ModalSuccess from "@/components/forms/ModalSuccess.vue";


import axios from "axios";

export default {
    components: {
        // SendFormModal,
        ModalSuccess,
        TextInput,
        TextareaIanput,
    },
    data() {
        return {
            showModal: {
                type: 'contact-form',
                show: false,
            },
            agreement_1: null,
            inputs: {
                name: {
                    type: 'text',
                    title: 'Imię i Nazwisko',
                    placeholder: 'Imię i Nazwisko',
                    mandatory: '*',
                    subtitle: null,
                    model: 'contactForm_name',
                    stage: 'contactForm.name'
                },
                email: {
                    type: 'text',
                    title: 'E-mail',
                    placeholder: 'E-mail',
                    mandatory: '*',
                    subtitle: null,
                    model: 'contactForm_email',
                    stage: 'contactForm.email'
                },
                text: {
                    type: 'text',
                    title: 'Treść wiadomości',
                    placeholder: 'Treść wiadomości',
                    mandatory: '*',
                    subtitle: null,
                    model: 'contactForm_text',
                    stage: 'contactForm.text'
                },
                contactForm: {
                    title: null,
                    subtitle: null,
                    model: 'contactForm',
                    stage: 'contactForm.contactForm'
                },
            },
            contactTerms: [
                {
                    id: "1",
                    description:
                        "Przyjmuję do wiadomości, że współadministratorami danych osobowych podanych przeze mnie w formularzu kontaktowym są Enea S.A. z siedzibą w Poznaniu (60-198) przy ul. Pastelowej 8 oraz Fundacja Enea z siedzibą w Poznaniu (60-198) przy ul. Pastelowej 8. <br><br> Podstawą przetwarzania podanych danych na potrzeby udzielenia odpowiedzi na złożone zapytanie przesłane na adres skrzynki kontaktowej dostępnej na stronie akcji „Enea Akademia Talentów” jest prawnie uzasadniony interes współadminitratorów (art. 6 ust. 1 lit. f RODO) polegający na udzieleniu odpowiedzi na złożone zapytanie. Podanie przeze mnie danych osobowych jest dobrowolne, ale konieczne do uzyskania odpowiedzi na pytanie wysłane przeze mnie za pośrednictwem formularza kontaktowego. Współadministratorzy postanowili o ustanowieniu wspólnego punktu kontaktowego, z którym można się kontaktować e-mailowo na adres: esa.iod@enea.pl lub listownie na adres: Inspektor Ochrony Danych (IOD) Enea S.A., ul. Pastelowa 8, 60-198 Poznań. Podane przeze mnie dane będą przetwarzane wyłącznie w czasie niezbędnym do udzielenia odpowiedzi na złożone zapytanie w ramach skrzynki kontaktowej. Odbiorcami moich danych osobowych mogą być podmioty świadczące usługi hostingowe, informatyczne, prawne lub księgowe. Przysługuje mi prawo dostępu do podanych danych oraz otrzymania ich kopii, prawo żądania ich sprostowania, poprawienia, usunięcia, ograniczenia ich przetwarzania, prawo wniesienia sprzeciwu wobec ich przetwarzania, a także prawo wniesienia skargi do organu nadzorczego — Prezesa Urzędu Ochrony Danych Osobowych.",
                    checked: false,
                    required: true,
                },
            ],
            form: {},
            readMoreActivated: {},
            termsErrors: [],
            errors: [],
            submitting: false,
        };
    },
    computed: {
        agreement_1_error() {
            const error = this.$store.getters.getError('agreement_1');
            if (error && error.length)
                return error[0]

            return null
        },
    },
    watch: {
        agreement_1(newVal) {
            this.updateFormValue('contactForm.agreement_1', !newVal ? null : true);
            this.$store.commit('removeError', 'agreement_1')
        },
    },
    methods: {
        async sendForm() {
            const form = this.$store.getters.getValuByPath('contactForm')
            axios
                .post(process.env.VUE_APP_API_BASE_URL + "contact", {
                    ...form,
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.showModal.type = 'contact-form'
                        this.showModal.show = true
                    } else {
                        this.showModal.type = 'server-error'
                        this.showModal.show = true
                    }
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        this.$store.commit('setErrors', error.response.data.errors);
                    } else {
                        this.showModal.type = 'server-error'
                        this.showModal.show = true
                    }
                });

        },
        checkmarkExpand(id) {
            this.readMoreActivated[id] = true;
        },
        checkmarkCollapse(id) {
            this.readMoreActivated[id] = false;
        },
    },
};
</script>
