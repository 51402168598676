<template>
    <section-layout class="section-about">
        <div class="section-about__content">
            <div class="d-flex flex-column flex-sm-row">
                <div class=" col-sm-12">
                    <img src="@/assets/about-what.jpg" alt="" class="section-about__image" />
                </div>
                <div class="col-sm-12 d-flex flex-column justify-content-center -pl-sm-5">
                    <div class="col-lg-19">
                        <p class="section-about__title heading-40 color-blue">Poznaj Eneę Akademię Talentów</p>
                        <p class="text-16">
                            Nasza Akademia to wspólna akcja Enei i Fundacji Enea, w której wspieramy młode talenty w
                            rozwijaniu ich największych pasji poprzez atrakcyjne stypendia. To już VI edycja, a w niej
                            do wygrania łącznie aż<span class="heading-16 color-blue"> 40 stypendiów po 5000
                                zł</span> w czterech kategoriach: <span class="heading-16 color-blue">sport, nauka,
                                sztuka, zaangażowanie społeczne
                                i ekologia.</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="row -pt-4 -pt-sm-12 d-flex align-items-center">
                <div class="col-sm-12 order-sm-2">
                    <img src="@/assets/about-who.svg" alt="" class="section-about__image" />
                </div>
                <div class="col-sm-12 text-16 -pr-sm-5 order-sm-1">
                    <div class="col-lg-19">
                        <p class="section-about__title heading-40 color-blue">Przekazaliśmy już
                            1&nbsp;308&nbsp;000&nbsp;zł! </p>
                        <p class="text-16">Już po raz szósty, we wspólnej akcji Enei i Fundacji Enea, przyznajemy
                            atrakcyjne nagrody pieniężne dla uczniów szkół podstawowych
                            i ponadpodstawowych. Do tej pory wyróżniliśmy i wsparliśmy młode talenty stypendiami o
                            łącznej wartości 1&nbsp;308&nbsp;000&nbsp;złotych! </p>
                    </div>
                </div>
            </div>
            <div class="d-flex -pt-4 -pt-sm-12 flex-column flex-sm-row">
                <div class=" col-sm-12">
                    <img src="@/assets/about-what_2.jpg" alt="" class="section-about__image" />
                </div>
                <div class="col-sm-12 d-flex flex-column justify-content-center -pl-sm-5">
                    <div class="col-lg-19">
                        <p class="section-about__title heading-40 color-blue">Kto może się zgłosić do&nbsp;konkursu?</p>
                        <p class="">Do naszej akcji zapraszamy wszystkich uczniów: </p>
                        <ul class="d-flex flex-column list">
                            <li> <span class="heading-16 color-blue">szkół podstawowych (klasy 4-8), </span></li>
                            <li> <span class="heading-16 color-blue">szkół ponadpodstawowych z całej Polski!
                                </span></li>
                        </ul>
                        <p>Zgłoś się w kategorii, która odpowiada Twojemu talentowi, i graj o <span
                                class="heading-16 color-blue">5000 zł, które możesz przeznaczyć na rozwój pasji!</span>
                        </p>
                    </div>
                </div>
            </div>

        </div>

    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
    },
};
</script>
