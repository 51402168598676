<template>
    <section-layout class="section-prize">
        <div class="-mt-4 -mt-sm-12">
            <div class="container">
                <div class="d-flex justify-content-center">
                    <img src="@/assets/prize.svg" alt="" class="section-prize__image d-none d-xs1-block" />
                    <img src="@/assets/prize_mobile.jpg" alt="" class="section-prize__image d-xs1-none" />
                </div>
            </div>
        </div>
    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
    },
};
</script>
