<template>
    <div class="form-data">
        <div v-for="(item, index) in form" :key="'achievements_' + index">
            <div class="heading-24 form__title mb-4 text-center">
                <div v-if="index == 0">I osiągnięcie</div>
                <div class="-pt-4" v-if="index == 1">II osiągnięcie</div>
                <div class="-pt-4" v-if="index == 2">III osiągnięcie</div>
            </div>
            <div class="row form__gap-24">
                <div class="col-md-12 text-18">
                    <text-input v-model="item.name" :data="inputs.name" :index="index" />
                </div>
                <div class="col-md-12">
                    <p class="form__title text-18 form__pb-8">Uzyskany wynik <span class="form__label form__label--red"
                            v-if="index == 0">*</span></p>
                    <div :class="{ 'vue-select__error': degreeError(index) }">
                        <vue-select :close-on-select="true" v-model="item.score" :options="inputs.score"
                            placeholder="Uzyskany wynik" label-by="name" value-by="name"
                            @selected="removeError(index, 'score')">
                            <template #label="{ selected }">
                                <template v-if="selected">
                                    <div>{{ selected.name }}</div>
                                </template>
                            </template>
                        </vue-select>
                    </div>
                    <div class="text-danger" v-if="scoreError(index)">
                        {{ scoreError(index) }}
                    </div>
                </div>
                <div class="col-md-12">
                    <p class="form__title text-18 form__pb-8">Ranga konkursu/olimpiady/zawodów/itd.<span
                            class="form__label form__label--red text-18" v-if="index == 0">*</span></p>
                    <div :class="{ 'vue-select__error': degreeError(index) }">
                        <vue-select :close-on-select="true" v-model="item.degree" :options="inputs.degree"
                            placeholder="Ranga konkursu/olimpiady/zawodów/itd." label-by="name" value-by="name"
                            @selected="removeError(index, 'degree')">
                            <template #label="{ selected }">
                                <template v-if="selected">
                                    <div>{{ selected.name }}</div>
                                </template>
                            </template>
                        </vue-select>
                    </div>
                    <div class="text-danger" v-if="degreeError(index)">
                        {{ degreeError(index) }}
                    </div>
                </div>
                <div class="col-md-12 text-18" v-if="item.degree == 'inny'">
                    <text-input v-model="item.another_degree" :data="inputs.another_degree" :index="index" />
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center -pt-5">
            <button class="button__red " @click="addAchievement" :disabled="form.length > 2 || buttonAchievments">
                <p v-if="form.length < 3">Dodaj kolejne osiągnięcie</p>
                <p v-if="form.length === 3">Dodano maksymalną ilość instytucji</p>
                <img src="@/assets/add-btn.svg" alt="" class="button__arrow" />
            </button>
        </div>
    </div>
</template>

<script>
import VueNextSelect from 'vue-next-select'
import TextInput from "@/components/inputs/TextInput.vue";

export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],
    data() {
        return {
            inputs: {
                name: {
                    type: 'text',
                    title: 'Nazwa konkursu/olimpiady/zawodów/itd.',
                    placeholder: 'Nazwa konkursu/olimpiady/zawodów/itd.',
                    subtitle: null,
                    mandatoryAchievements: '*',
                    model: 'most_achievments_name',
                    stageIndex: 'formStudent.student.most_achievements.stageIndex.name'
                },
                another_degree: {
                    type: 'text',
                    title: 'Określ inną rangę',
                    placeholder: 'Określ inną rangę',
                    subtitle: null,
                    mandatory: '*',
                    model: 'another_degree',
                    stageIndex: 'formStudent.student.most_achievements.stageIndex.degree'
                },
                score: [
                    {
                        name: '1 miejsce (Finalista)',
                        value: 1
                    },
                    {
                        name: '2 miejsce',
                        value: 1
                    },
                    {
                        name: '3 miejsce',
                        value: 1
                    },
                    {
                        name: 'wyróżnienie',
                        value: 1
                    },
                    {
                        name: 'miejsca 4-5',
                        value: 1
                    },
                    {
                        name: 'miejsca 6-10',
                        value: 1
                    },
                    {
                        name: 'miejsca od 11',
                        value: 1
                    },
                ],
                degree: [
                    {
                        name: 'międzynarodowy',
                        value: 1
                    },
                    {
                        name: 'ogólnopolski',
                        value: 1
                    },
                    {
                        name: 'wojewódzki',
                        value: 1
                    },
                    {
                        name: 'regionalny (miejski)',
                        value: 1
                    },
                    {
                        name: 'szkolny',
                        value: 1
                    },
                    {
                        name: 'inny',
                        value: 1
                    },
                ],
            },
            form: [
                {
                    name: '',
                    score: null,
                    degree: null,
                    another_degree: ''
                }
            ]
        }
    },
    components: {
        'vue-select': VueNextSelect,
        TextInput
    },
    computed: {
        buttonAchievments(){
            return this.form.filter((n) => !n.name || n.name == '' || !n.score || n.score == '' || !n.degree || n.degree == '').length;
        }
    },
    watch: {
        form: {
            handler(newVal) {
                this.$emit('update:modelValue', newVal)
                this.updateFormValue('formStudent.student.most_achievements', newVal);
            },
            deep: true
        }
    },
    methods: {
        removeError(index, value) {
            this.$store.commit('removeError', 'student.most_achievements.' + index + '.' + value)
        },
        degreeError(index) {
            const error = this.$store.getters.getError('student.most_achievements.' + index + '.degree');
            if (error && error.length)
                return error[0]

            return null
        },
        scoreError(index) {
            const error = this.$store.getters.getError('student.most_achievements.' + index + '.score');
            if (error && error.length)
                return error[0]

            return null
        },
        addAchievement() {
            this.form.push(
                {
                    name: '',
                    score: null,
                    degree: null,
                    another_degree: ''
                }
            );
        }
    },
};
</script>
