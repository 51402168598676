<template>
    <div class="radio_input d-flex flex-column">
        <p class="heading-24 form__title text-center -pt-4 form__pb-8" v-if="data.title">{{ data.title }}
            <span class="form__label form__label--red">{{ data.mandatory }}</span>
        </p>
        <div class="text-center text-20" v-if="data.subtitle">{{ data.subtitle }}</div>
        <div class="d-flex flex-sm-row justify-content-center align-items-start form__gap-24 form__gap-50 form__pt-24"
            :class="{ 'flex-column': !data.flexRow }">
            <div class="form-check form-check-inline form__gap-12" v-for="(item, index) in data.options"
                :key="data.model + '_' + index">
                <input :name="data.model" class="form-check-input" :class="{ 'form-check-input__error': error }"
                    type="radio" v-model="value" :value="item.value" :id="data.model + index">
                <span>
                    <label :for="data.model + '_' + index"
                        class="form-check-label heading-20 d-inline-block form__inputTop"
                        :accesskey="data.model + index">{{ item.name }}<br><span class="text-16">{{ item.small
                            }}</span></label>
                    <PopperPopper v-if="item.tooltip" :hover="true" :arrow="true" :content="item.tooltip">
                        <button class="form__tooltip"></button>
                    </PopperPopper>
                </span>
            </div>
        </div>
        <div class="text-center text-danger -pt-1 -pb-4" v-if="error">
            {{ error }}
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            value: null,
        }
    },
    components: {
    },
    mounted() {
        if (this.data.autoload && !this.$store.getters.getValuByPath(this.data.stage) && this.$store.getters.getValuByPath(this.data.value)) {
            this.value = "yes";
        } else if (this.data.autoload && this.$store.getters.getValuByPath(this.data.stage)) {
            this.value = this.$store.getters.getValuByPath(this.data.stage);
        }
    },
    watch: {
        value(newVal) {
            this.$emit('update:modelValue', newVal);
            if (this.data.stage) {
                this.updateFormValue(this.data.stage, newVal)
                this.$store.commit('removeError', this.data.stage)
            }
            if (this.data.stageIndex) {
                this.$store.commit('removeError', this.data.stageIndex.replace('stageIndex', this.index))
            }
        }
    },
    computed: {
        error() {
            if (this.data.stage) {
                const path = this.data.stage.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '')
                const error = this.$store.getters.getError(path);
                if (error && error.length)
                    return error[0]

                return null
            }
            if (this.data.stageIndex) {
                const path = this.data.stageIndex.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '').replace('stageIndex', this.index)
                const error = this.$store.getters.getError(path);
                if (error && error.length)
                    return error[0]

                return null
            }
            return null
        }
    },
    emits: ['update:modelValue'],
    props: ['data', 'modelValue', 'index']
};
</script>
