<template>
    <div class="form-data">
        <div class="row">
            <div class="col-sm-12 -pt-4">
                <text-input :data="inputs.name" />
            </div>
            <div class="col-sm-12 -pt-4">
                <text-input :data="inputs.city" />
            </div>
        </div>

        <textarea-ianput :data="inputs.purpose_project" />
        <textarea-ianput :data="inputs.desc" />
        <textarea-ianput :data="inputs.budget" />
    </div>
</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";
import TextareaIanput from "@/components/inputs/TextareaIanput.vue";


export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],

    components: {
        TextInput,
        TextareaIanput,
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    data() {
        return {
            inputs: {
                name: {
                    type: 'text',
                    title: 'Nazwa projektu',
                    placeholder: 'Nazwa projektu',
                    subtitle: null,
                    mandatory: '*',
                    model: 'project_name',
                    stage: 'formOrganizationStep2.project.name'
                },
                city: {
                    type: 'text',
                    title: 'Miejscowość realizacji projektu',
                    placeholder: 'Miejscowość realizacji projektu',
                    subtitle: null,
                    mandatory: '*',
                    model: 'project_name',
                    stage: 'formOrganizationStep2.project.city'
                },
                desc: {
                    type: 'textarea',
                    title: 'Opis projektu, który znajdzie się na stronie Enei Akademii Talentów',
                    placeholder: 'Opis projektu, który znajdzie się na stronie Enei Akademii Talentów',
                    subtitle: null,
                    mandatory: '*',
                    maxlength: 3000,
                    tooltip: 'Krótka charakterystyka projektu/na czym polega projekt, który znajdzie się na profilu organizacji, widocznym dla osób głosujących',
                    model: 'project_desc',
                    stage: 'formOrganizationStep2.project.desc'
                },
                budget: {
                    type: 'textarea',
                    title: 'Jak zostaną przeznaczone środki?',
                    placeholder: 'Jak zostaną przeznaczone środki?',
                    subtitle: null,
                    mandatory: '*',
                    maxlength: 3000,
                    model: 'project_budget',
                    stage: 'formOrganizationStep2.project.budget_destiny'
                },
                purpose_project: {
                    type: 'textarea',
                    title: 'Odbiorcy (beneficjenci) projektu ',
                    placeholder: 'Odbiorcy (beneficjenci) projektu ',
                    subtitle: null,
                    mandatory: '*',
                    model: 'recipients_project',
                    stage: 'formOrganizationStep2.project.recipients_project'
                },
            },
        }
    },
};
</script>
