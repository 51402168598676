<template>
    <main-layout>
        <div class="-pt-nav  ">
            <div class="container background__pg-application">
                <div>
                    <div class="row">
                        <div class="col-sm-18 offset-sm-3 -pt-sm-4">
                            <button @click="$router.go(-1)" class="button__back">
                                <img src="@/assets/arrow-left.svg" alt="" class="button__arrow" />
                                <span v-if="!finalistActive" >Wróć do listy finalistów</span>
                                <span v-if="finalistActive">Wróć do listy finalistów</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class=" -pt-3 -pb-2 -pb-sm-12 d-flex justify-content-center">
                    <section-layout class="section-news__items col-sm-18">
                        <div class="row">
                            <div class="col-md-11 position-relative">
                                <img class="w-100" :src="post.img" alt="">
                                <img v-if="finalistActive && post.winner == 1" src="@/assets/Badge-JURY.svg" alt=""
                                    class="img-budge" />
                                <img v-if="finalistActive && post.winner == 2" src="@/assets/Badge-INTERNAUCI.svg"
                                    alt="" class="img-budge" />
                            </div>
                            <div class="col-md-12 offset-md-1">
                                <div v-if="post.type != 'organization'" class="text-capitalize">Kategoria: {{
                                    post.category
                                }}</div>
                                <div class="heading-40 color-blue mb-2">
                                    {{ post.application_name }}
                                    <span v-if="post.class">
                                        - {{ post.class }}. klasa
                                    </span>
                                </div>
                                <div class="text-24 color-blue mb-4 mb-md-5">Miejscowość: {{ post.city }} </div>
                                <div class="d-flex justify-content-between align-items-center " >
                                    <div class="text-24 color-blue">Ilość głosów: <span class="fw-bold">{{
                                        votes
                                    }}</span></div>
                                    <div v-if="votesActive"><button class="button__red" @click="scrollVoteForm">Zagłosuj
                                            <img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <div class="heading-32 color-blue text-center fw-bold mb-4"
                                v-if="post.type == 'organization'">
                                {{ post.project_name }}
                            </div>
                            <div class="heading-18 color-blue fw-bold mb-4">
                                Opis finalisty
                            </div>
                            <p v-text="post.application_desc" style="white-space:break-spaces;"></p>
                        </div>
                        <div>
                            <div class="heading-18 color-blue fw-bold mb-3">
                                Materiał konkursowy
                            </div>
                            <div class="section-participants__video video-container">
                                <iframe id="videoContainer" :src="'https://www.youtube.com/embed/' + post.video_id"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                        <div v-if="votesActive">
                            <p class="heading-24 form__title -pb-4 text-center d-none d-sm-block">
                                Oddaj głos na tego finalistę
                            </p>
                            <div class="row">
                                <div class="col-md-10">
                                    <div v-if="post.type != 'organization'" class="text-capitalize">Kategoria: {{
                                        post.category
                                    }}</div>
                                    <div class="heading-40 color-blue mb-2">
                                        {{ post.application_name }}
                                        <span v-if="post.class">
                                            - {{ post.class }}. klasa
                                        </span>
                                    </div>
                                    <div class="text-24 color-blue mb-4 mb-md-5">Miejscowość: {{ post.city }} </div>
                                </div>
                                <div class="col-md-12 offset-md-2" id="vote-form">
                                    <VoteForm :id="post.id" />
                                </div>
                            </div>
                        </div>
                    </section-layout>
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import VoteForm from "@/components/forms/VoteForm.vue";

import axios from "axios";

// Sections

export default {
    components: {
        MainLayout,
        VoteForm
    },
    methods: {
        openModal(id) {
            this.activeId = id;
            this.showModal.show = true;
        },
        scrollVoteForm() {
            setTimeout(() => {

                const el = document.querySelector('#vote-form');

                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({ behavior: 'smooth' });

                    const pos = el.style.position;
                    const top = el.style.top;
                    el.style.position = 'relative';
                    el.style.top = '-200px';
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    el.style.top = top;
                    el.style.position = pos;
                }
            }, 200);
        }
    },
    computed: {
        votesActive() {
            return this.$store.state.votesActive
        },
        finalistActive() {
            return this.$store.state.finalistActive
        }
    },
    watch: {
    },
    data() {
        return {
            activeId: null,
            showModal: {
                show: false
            },
            post: {
            },
            errors: null,
            votes: 0
        }
    },
    beforeRouteEnter(to, from, next) {
        axios
            .all([
                axios({
                    method: "get",
                    url: process.env.VUE_APP_API_BASE_URL + "applications/finalists/" + to.params.id,
                }),
            ])
            .then(
                axios.spread((postsResponse) => {
                    console.log(postsResponse);
                    next((vm) => {
                        vm.post = postsResponse.data.application;
                        vm.votes = postsResponse.data.votes;
                    });
                })
            )
            .catch((error) => {
                this.errors = error;
            });
    },

};
</script>
