<template>
    <div class="form-data">
        <!-- Osoba zgłaszająca -->
        <radio-input v-model="reporting_person" class="d-flex form__gap justify-content-center" :data="inputs.person_reporting" />

        <div v-show="person_reporting">
            <div class="form__title heading-24 text-center -pt-4 form__pb-8">
                Dane ucznia
            </div>
            <p class="text-20 text-center" v-show="person_reporting == 'student'">Dane osoby ubiegającej się o stypendium.</p>

            <!-- Dane ucznia -->
            <div class="row form__pt-24 form__gap-24">
                <div class="col-md-12">
                    <text-input :data="inputs.name" />
                </div>
                <div class="col-md-12">
                    <text-input :data="inputs.surname" />
                </div>
                <div class="col-md-12">
                    <!-- <text-input :data="inputs.student_birth_date" /> -->
                    <date-input type="date" :data="inputs.student_birth_date" />

                </div>
                <div class="col-md-12">
                    <text-input :data="inputs.student_class" />
                </div>
                <div class="col-md-12" v-show="person_reporting == 'student'">
                    <text-input-number :data="inputs.phone" />
                </div>
                <div class="col-md-12" v-show="person_reporting == 'student'">
                    <text-input :data="inputs.email" />
                </div>
            </div>

            <!-- Grupa wiekowa - jeżeli uczeń niepełnoletni -->
            <div v-show="person_reporting == 'parent'">
                <radio-input :data="inputs.type" />
            </div>

            <p class="form__title text-center heading-24 -pt-4">Adres zamieszkania ucznia</p>
            <address-form stage-name="formStudent.student.address" class="form__pt-24 form__gap-24" />

            <!-- Dane gdy uczeń pełnoletni -->
            <div v-show="person_reporting== 'student'">
                <radio-input v-model.number="form.student.contact_address_select"
                    :data="inputs.contact_address_select" />
                <address-form stage-name="formStudent.student.contact_address"
                    v-show="form.student.contact_address_select == 'yes'" v-model="form.student.contact_address" />
            </div>

            <!-- Dane gdy uczeń niepełnoletni -->
            <div v-show="person_reporting == 'parent'">
                <div class="heading-24 form__title -pt-4 text-center">
                    Dane wnioskodawcy
                </div>
                <div class="row form__pt-24 form__gap-24">
                    <div class="col-md-12">
                        <text-input v-model="form.parent.name" :class="{'form-control__error' : error}" :data="inputs.parent_name" />
                    </div>
                    <div class="col-md-12">
                        <text-input v-model="form.parent.surname" :data="inputs.parent_surname" />
                    </div>
                    <div class="col-md-12">
                        <text-input-number v-model="form.parent.phone" :data="inputs.parent_phone" />
                    </div>
                    <div class="col-md-12">
                        <text-input v-model="form.parent.email" :data="inputs.parent_email" />
                    </div>
                </div>

                <radio-input v-model.number="form.parent.address_select" :data="inputs.address_select_parent" />
                <address-form stage-name="formStudent.person.address" v-show="form.parent.address_select == 'yes'"
                    v-model="form.parent.address" />

                <radio-input v-model.number="form.parent.contact_address_select"
                    :data="inputs.contact_address_select_parent" />
                <address-form stage-name="formStudent.person.contact_address"
                    v-show="form.parent.contact_address_select == 'yes'" v-model="form.parent.address" />
            </div>
        </div>
    </div>
</template>

<script>
import RadioInput from "@/components/inputs/RadioInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import TextInputNumber from "@/components/inputs/TextInputNumber.vue";
// import OrganizationData from "@/components/forms/OrganizationData.vue";
import AddressForm from "@/components/forms/AddressForm.vue";
import DateInput from "@/components/inputs/DateInput.vue";



export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],

    components: {
        RadioInput,
        TextInput,
        TextInputNumber,
        AddressForm,
        DateInput
    },
    computed: {
        person_reporting() {
            return this.$store.getters.getValuByPath('formStudent.student.person_reporting');
        }
    },
    watch: {
        reporting_person(newVal){
            if(newVal == 'student'){
                this.updateFormValue('formStudent.application.type', 'secondary_school');
            }else{
                this.updateFormValue('formStudent.application.type', null)
            }
        }
    },
    methods: {
    },
    data() {
        return {
            reporting_person: null,
            inputs: {
                person_reporting: {
                    title: null,
                    subtitle: null,
                    options: [
                        {
                            name: 'Rodzic lub opiekun prawny',
                            small: '(w przypadku dziecka poniżej 18 roku życia)',
                            value: 'parent'
                        },
                        {
                            name: 'Uczeń pełnoletni ',
                            value: 'student'
                        }
                    ],
                    model: 'person_reporting',
                    stage: 'formStudent.student.person_reporting'
                },
                name: {
                    type: 'text',
                    title: 'Imię/Imiona',
                    placeholder: 'Imię/Imiona',
                    mandatory: '*',
                    subtitle: null,
                    model: 'student_name',
                    stage: 'formStudent.student.name'
                },
                surname: {
                    type: 'text',
                    title: 'Nazwisko',
                    placeholder: 'Nazwisko',
                    subtitle: null,
                    mandatory: '*',
                    model: 'student_surname',
                    stage: 'formStudent.student.surname'
                },
                student_birth_date: {
                    type: 'text',
                    title: 'Data urodzenia',
                    placeholder: 'Data urodzenia',
                    subtitle: null,
                    mandatory: '*',
                    model: 'student_birth_date',
                    stage: 'formStudent.student.birth_date'
                },
                student_class: {
                    type: 'text',
                    title: 'Klasa',
                    placeholder: 'Klasa',
                    subtitle: null,
                    mandatory: '*',
                    model: 'student_class',
                    stage: 'formStudent.student.class'
                },
                phone: {
                    type: 'text',
                    title: 'Nr telefonu',
                    placeholder: 'Nr telefonu',
                    subtitle: null,
                    mandatory: '*',
                    tooltip:'Z tym numerem telefonu zostanie podjęta próba połączenia w razie potrzeby.',
                    model: 'student_phone',
                    stage: 'formStudent.student.phone'
                },
                email: {
                    type: 'text',
                    title: 'E-mail',
                    placeholder: 'E-mail',
                    subtitle: null,
                    mandatory: '*',
                    tooltip:'Na ten adres e-mail będzie kierowana wszelka korespondencja dotycząca akcji',
                    model: 'student_email',
                    stage: 'formStudent.student.email'
                },
                type: {
                    type: 'radio',
                    title: 'Grupa wiekowa',
                    subtitle: null,
                    mandatory: '*',
                    options: [
                        {
                            name: 'szkoła podstawowa (od 4 klasy)',
                            small: null,
                            value: 'primary_school'
                        },
                        {
                            name: 'szkoła ponadpodstawowa',
                            value: 'secondary_school'
                        }
                    ],
                    model: 'type',
                    stage: 'formStudent.application.type'
                },
                contact_address_select: {
                    type: 'radio',
                    title: 'Adres korespondencyjny',
                    subtitle: 'Należy wybrać jedną z dwóch opcji:',
                    // flexRow: true,
                    options: [
                        {
                            name: 'taki jak adres zamieszkania ucznia',
                            value: 'no'
                        },
                        {
                            name: 'inny',
                            value: 'yes'
                        }
                    ],
                    model: 'contact_address_select',
                    stage: 'formStudent.student.contact_address_select'
                },
                parent_name: {
                    type: 'text',
                    title: 'Imię/Imiona',
                    placeholder: 'Imię/Imiona',
                    subtitle: null,
                    mandatory: '*',
                    model: 'parent_name',
                    stage: 'formStudent.person.name'
                },
                parent_surname: {
                    type: 'text',
                    title: 'Nazwisko',
                    placeholder: 'Nazwisko',
                    subtitle: null,
                    mandatory: '*',
                    model: 'parent_surname',
                    stage: 'formStudent.person.surname'
                },
                parent_phone: {
                    type: 'text',
                    title: 'Nr telefonu',
                    placeholder: 'Nr telefonu',
                    subtitle: null,
                    mandatory: '*',
                    tooltip:'Z tym numerem telefonu zostanie podjęta próba połączenia w razie potrzeby.',
                    model: ' parent_phone',
                    stage: 'formStudent.person.phone'
                },
                parent_email: {
                    type: 'text',
                    title: 'E-mail',
                    placeholder: 'E-mail',
                    subtitle: null,
                    mandatory: '*',
                    tooltip:'Na ten adres e-mail będzie kierowana wszelka korespondencja dotycząca akcji',
                    model: 'parent_email',
                    stage: 'formStudent.person.email'
                },
                address_select_parent: {
                    type: 'radio',
                    title: 'Adres zamieszkania Wnioskodawcy',
                    subtitle: null,
                    mandatory: '*',
                    // flexRow: true,
                    options: [
                        {
                            name: 'taki jak adres zamieszkania ucznia',
                            value: 'no'
                        },
                        {
                            name: 'inny',
                            value: 'yes'
                        }
                    ],
                    model: 'address_select_parent',
                    stage: 'formStudent.person.address_select'
                },
                contact_address_select_parent: {
                    type: 'radio',
                    title: 'Adres korespondencyjny',
                    mandatory: '*',
                    subtitle: null,
                    // flexRow: true,
                    options: [
                        {
                            name: 'taki jak adres zamieszkania ucznia',
                            value: 'no'
                        },
                        {
                            name: 'inny',
                            value: 'yes'
                        }
                    ],
                    model: 'contact_address_select_parent',
                    stage: 'formStudent.person.contact_address_select'
                },

            },
            form: {
                type: null,
                student: {
                    name: '',
                    surname: '',
                    birth_date: '',
                    class: '',
                    phone: '',
                    email: '',
                    desc: '',
                    address: {},
                    contact_address_select: null,
                    contact_address: {}
                },
                parent: {
                    name: '',
                    surname: '',
                    phone: '',
                    email: '',
                    address_select: null,
                    address: {},
                    contact_address_select: null,
                    contact_address: {}
                }
            }
        }
    },
};
</script>
