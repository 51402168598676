<template>
    <main-layout>
        <div class="-pt-nav -pb-xs1-4 -pb-sm-12">
            <div class="container background__icons-sm">
                <SectionDownloads />
            </div>
        </div>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import SectionDownloads from "../components/sections/SectionDownloads.vue";

export default {
    components: {
    MainLayout,
    SectionDownloads,
},
    computed: {
    }
};
</script>
