<script>
export default {
  data() {
    return {
      value: '',
      maxlength: 500
    }
  },
  components: {
  },
  mounted(){
    if(this.data.maxlength){
      this.maxlength = this.data.maxlength
    }
  },
  watch: {
    value(newVal) {
      this.$emit('update:modelValue', newVal);
      if (this.data.stage){
        this.updateFormValue(this.data.stage, newVal);
        this.$store.commit('removeError', this.data.stage)
      }
      if(this.data.stageIndex){
        this.$store.commit('removeError', this.data.stageIndex.replace('stageIndex', this.index))
      }
    }

  },
  computed: {
    error() {
      if (this.data.stage) {
        const path = this.data.stage.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '')
        const error = this.$store.getters.getError(path);
        if (error && error.length)
        return error[0]
      
      return null
    }
    if(this.data.stageIndex){
      const path = this.data.stageIndex.replace('formStudent.', '').replace('formOrganization.', '').replace('contactForm.', '').replace('formOrganizationStep2.', '').replace('formStudentStep2.', '').replace('stageIndex', this.index)
        console.log(path)
        const error = this.$store.getters.getError(path);
        if (error && error.length)
          return error[0]

        return null
      }
      return null
    }
  },
  emits: ['update:modelValue'],
  props: ['data', 'modelValue', 'index']
}
</script>

<template>
  <div class="radio_input -pt-4">
    <p class="text-18 form__title form__pb-8" v-if="data.title">{{data.title}} 
      <PopperPopper v-if="data.tooltip" :hover="true" :arrow="true" :content="data.tooltip">
        <button class="form__tooltip"></button>
      </PopperPopper>
      <span class="form__label form__label--red">{{data.mandatory}}</span></p>
    <div v-if="data.subtitle">{{data.subtitle}}</div> 
    <textarea @input="$emit('update:modelValue', $event.target.value)" :placeholder="data.placeholder" class="form-control form__label form__label--black" rows="7"
      :maxlength="maxlength" v-model="value" :class="{'form-control__error' : error}"></textarea>

    <small class="text-16 -pt-1 form__description">
      Liczba znaków: {{value.length}}/{{maxlength}}
    </small>
    <div class="text-danger" v-if="error">
      {{error}}
    </div>
  </div>
</template>