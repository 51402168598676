<template>
    <section-layout class="section-howToWin">
        <div class="d-flex justify-content-center">
            <div class="section-howToWin__content col-sm-16 -pt-4 -pt-sm-14">
                <p class="heading-40 color-blue text-center mb-5">Jak wziąć udział<br>i… wygrać stypendium!</p>

                <div class="section-howToWin__item d-flex flex-column flex-sm-row align-items-center">
                    <div class="section-howToWin__image-container" :class="{ 'opacity-50': stageStatus >= 3 }">
                        <img src="@/assets/HowToWin-pic1.svg" alt="" class="section-howToWin__image" />
                        <img src="@/assets/HowToWin-nr1.svg" alt=""
                            class="section-howToWin__image-number d-none d-sm-block" />
                        <img src="@/assets/HowToWin-nr1-mobile.svg" alt=""
                            class="section-howToWin__image-number d-sm-none" />
                    </div>
                    <div class="d-flex align-items-center" :class="{ 'opacity-50': stageStatus >= 3 }">
                        <div class="section-howToWin__content">
                            <p class="heading-24 color-blue">Zgłoś się!</p>
                            <p class="section-howToWin__text text-16">Wypełnij formularz kontaktowy – napisz o sobie i
                                swoim talencie. <br><span class="heading-16 color-blue">Pamiętaj! Czekamy na Twoje
                                    zgłoszenie od 15 października do 10 listopada 2024 r.</span><br> Najciekawsi
                                kandydaci przejdą do kolejnego etapu. Może będziesz to Ty?</p>
                            <router-link v-if="stageStatus == 2" :to="{ name: 'signUpStudent' }"
                                class="button__red">Formularz zgłoszeniowy<img src="@/assets/right-btn.svg" alt=""
                                    class="button__arrow" /></router-link>
                        </div>
                    </div>
                </div>

                <div class="section-howToWin__item d-flex flex-column flex-sm-row align-items-center">
                    <div class="section-howToWin__image-container" :class="{ 'opacity-50': stageStatus >= 7 }">
                        <img src="@/assets/HowToWin-pic2.svg" alt="" class="section-howToWin__image" />
                        <img src="@/assets/HowToWin-nr2.svg" alt=""
                            class="section-howToWin__image-number d-none d-sm-block " />
                        <img src="@/assets/HowToWin-nr2-mobile.svg" alt=""
                            class="section-howToWin__image-number d-sm-none" />
                    </div>
                    <div class="d-flex align-items-center" :class="{ 'opacity-50': stageStatus >= 7 }">
                        <div class="section-howToWin__content">
                            <p class="heading-24 color-blue">Nagraj filmik i pokaż swój talent!</p>
                            <p class="section-howToWin__text text-16">
                                Jesteś w drugim etapie? Hurra! Teraz nagraj krótkie <span
                                    class="heading-16 color-blue">video o swojej pasji</span> i przekonaj nas, że to
                                właśnie do Ciebie powinno trafić stypendium Enei.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="section-howToWin__item d-flex flex-column flex-sm-row align-items-center">
                    <div class="section-howToWin__image-container" :class="{ 'opacity-50': stageStatus >= 8 }">
                        <img src="@/assets/HowToWin-pic3.svg" alt="" class="section-howToWin__image" />
                        <img src="@/assets/HowToWin-nr3.svg" alt=""
                            class="section-howToWin__image-number d-none d-sm-block" />
                        <img src="@/assets/HowToWin-nr3-mobile.svg" alt=""
                            class="section-howToWin__image-number d-sm-none" />
                    </div>
                    <div class="d-flex align-items-center" :class="{ 'opacity-50': stageStatus >= 8 }">
                        <div class="section-howToWin__content">
                            <p class="heading-24 color-blue">Głosujemy!</p>
                            <p class="section-howToWin__text text-16">Spośród filmów konkursowych opublikowanych na
                                stronie Enei Akademii Talentów <span class="heading-16 color-blue">internauci</span>
                                wybiorą te najciekawsze! Część zwycięzców
                                wybierze również jury.</p>
                        </div>
                    </div>
                </div>

                <div class="section-howToWin__item d-flex flex-column flex-sm-row align-items-center"
                    :class="{ 'opacity-50': stageStatus >= 10 }">
                    <!-- <img src="@/assets/HowToWin-pic4.svg" alt="" class="section-howToWin__image"/> -->
                    <div class="section-howToWin__image-container">
                        <img src="@/assets/HowToWin-pic4.svg" alt="" class="section-howToWin__image" />
                        <img src="@/assets/HowToWin-nr4.svg" alt=""
                            class="section-howToWin__image-number d-none d-sm-block" />
                        <img src="@/assets/HowToWin-nr4-mobile.svg" alt=""
                            class="section-howToWin__image-number d-sm-none" />
                    </div>
                    <div class="d-flex align-items-center" :class="{ 'opacity-50': stageStatus >= 10 }">
                        <div class="section-howToWin__content">
                            <p class="heading-24 color-blue">Zgarnij 5000 zł stypendium!</p>
                            <p class="section-howToWin__text text-16">Na <span class="heading-16 color-blue">40
                                    finalistów czekają stypendia po 5000 zł</span> w 4 kategoriach: nauka, sport,
                                sztuka, zaangażowanie społeczne i ekologia. Trzymamy kciuki!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            showContent: true,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
        applicationActive() {
            return this.$store.state.applicationActive
        },
        votesActive() {
            return this.$store.state.votesActive
        },
        finalistActive() {
            return this.$store.state.finalistActive
        },
        stageStatus() {
            return this.$store.state.stage
        }
    },

};
</script>
